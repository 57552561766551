import { Box, Button, Flex, Heading, Spinner, Tag, useStatStyles, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import useSingleCourse from "../../hooks/useSingleCourse";
import Logo from "../../ui/Logo";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../lib/firebase";
import useUserDoc from "../../hooks/useUserDoc";
import { loginUserWithPopup } from "../../lib/login";
import { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { addCourseToUser } from "../../lib/database";
import TermsModal from "../tutor/TermsModal";



export default function Join() {

    const params = useParams()
    const navigate = useNavigate()
    const toast = useToast()
    const [termsOpen, setTermsOpen] = useState(false)


    const [user, userLoading, userError] = useAuthState(auth)
    const [userDoc, isUserDoc] = useUserDoc(user)

    const [course, isLoading, adminLevels] = useSingleCourse(params.courseId, false, true)

    const [isAddingUserToCourse, setIsAddingUserToCourse] = useState(false)

    const handleJoinCourse = () => {
        // check that user's email is in the course's email list
        if (!Object.keys(adminLevels).includes(user.email)) {
            toast({
                title: 'Your email is not in the list of students/admin for this course.',
                description: "Make sure you're using your school email. Email the ATA team if you think there has been a mistake.",
                position: 'top',
                status: 'warning',
                duration: null,
                isClosable: true,
            })
            signOut(auth)
            return
        }

        setTermsOpen(true)
    }

    const handleAcceptTerms = () => {
        setTermsOpen(false)
        setIsAddingUserToCourse(true)


        const adminLevel = adminLevels[user.email] as number

        // add course id to userDoc
        addCourseToUser(user.uid, params.courseId, course.controls.quota.maxQueriesAccumulated, adminLevel)
    }

    useEffect(() => {
        if (!isUserDoc) return

        if (userDoc.courses.includes(params.courseId)) {
            navigate(`/app/${params.courseId}`)
        }
    }, [userDoc, isUserDoc])

    const handleLogin = () => {
        loginUserWithPopup()
    }

    return (
        <Flex mx='auto' flexDir={'column'} justifyContent='center' alignItems={'center'} maxW='6xl' p='32'>
            <Logo darkOnLight />

            {(isLoading || !course) ? <Spinner mt='32' /> : <>
                <Heading mt='16'>You've been invited to join</Heading>
                <Tag fontSize={'3xl'} px='6' py='3' my='16' size='lg' textAlign={'center'}>
                    {course.title} {course.semester} {course.year}
                </Tag>
                <Button
                    px='12'
                    fontSize='2xl'
                    size='lg'
                    colorScheme={'primary'}
                    color='white'
                    fontWeight={'400'}
                    onClick={isAddingUserToCourse ? undefined : (isUserDoc ? handleJoinCourse : handleLogin)}
                >
                    {isAddingUserToCourse ? <Spinner /> : (isUserDoc ? 'Join Course' : 'Login')}
                </Button>
            </>}

            <TermsModal
                isOpen={termsOpen}
                onTermsAccept={handleAcceptTerms}
            />

        </Flex>
    )
}