import { useEffect, useState } from "react";

export default function useStickyState<T>(defaultValue: T, key: string) {
    const [value, setValue] = useState<T>(() => {
        const stickyValue = window.localStorage.getItem(key)
        return stickyValue !== null
            ? JSON.parse(stickyValue)
            : defaultValue
    })

    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue] as [T, (item: T | ((item: T) => T)) => void]
}