import { INGESTION_SERVICE_URL } from "../../../lib/CONSTANTS"
import { auth } from "../../../lib/firebase"
import { DeleteFileRecord, IngestionServiceRequest, ResourceFormResult, UploadFileRecord } from "./types"
import { deleteFile, listSubpaths, listSubpathsOfSubDirs, uploadFile } from "../../../lib/storage";


export async function sendIngestRequest(req: IngestionServiceRequest, action: 'delete' | 'add_or_update') {
    const accessToken = await auth.currentUser.getIdToken()

    const headers = {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
    }

   const endpoint = INGESTION_SERVICE_URL + action

    const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body: JSON.stringify(req),
    })

    if(response.status != 200){
        throw (await response.json()).error
    }

    return response
}


/**
 * returns map[resourceType, fileName[]]
 */
export async function getMaterialFilesIngestedForResource(courseId: string, resourceClass: string, resourceName: string, resourceTypes: string[]){
    const path = `data/${courseId}/${resourceClass}/${resourceName}`
    const { files, prefixes } = await listSubpathsOfSubDirs(path, resourceTypes)

    const populatedMap = Object.fromEntries(resourceTypes.map(rt => [rt, []]))
    for(const path of files){
        const [_, __, ___, ____, resourceType, fileName] = path.split("/")
        populatedMap[resourceType].push(fileName)
    }

    return populatedMap
}

/**
 * given an rclass, rname pair, deletes all existing materials files from Cloud storage
 */
export async function deleteExistingResourceFiles(courseId: string, resourceClass: string, resourceName: string, resourceTypes: string[]) {
    const path = `data/${courseId}/${resourceClass}/${resourceName}`
    const { files, prefixes } = await listSubpathsOfSubDirs(path, resourceTypes)

    // delete each file in files in parallel
    const deletePromises = files.map(deleteFile)
    await Promise.all(deletePromises)
}

/**
 * Deletes material files in Cloud Storage given map[resourceType, DeleteRecords]
 */
export async function deleteResourceFiles(courseId: string, resourceClass: string, resourceName: string, resourceTypeToDeleteRecords: {[key: string]: DeleteFileRecord[]}){
    const pathsToDelete: string[] = []
    
    for(const [resourceType, deleteRecords] of Object.entries(resourceTypeToDeleteRecords)){
        for(const record of deleteRecords){
            const path = `data/${courseId}/${resourceClass}/${resourceName}/${resourceType}/${record.fileName}`
            pathsToDelete.push(path)
        }
    }

    // delete each file in files in parallel
    const deletePromises = pathsToDelete.map(deleteFile)
    await Promise.all(deletePromises)
}

/**
 * Uploads material files to Cloud Storage given a map[resourceType, UploadRecords]
 */
export function uploadResourceFiles(courseId: string, resourceClass: string, resourceName: string, resourceTypeToUploadRecords: { [key: string]: UploadFileRecord[] }) {
    // construct cloud storage path --> File map
    const pathsToFile = {}
    for (const [resourceType, uploadRecords] of Object.entries(resourceTypeToUploadRecords)) {
        for (const record of uploadRecords) {
            const path = `data/${courseId}/${resourceClass}/${resourceName}/${resourceType}/${record.file.name}`
            pathsToFile[path] = record.file
        }
    }

    const promises: Promise<unknown>[] = Object.entries(pathsToFile).map(
        ([path, file]) => uploadFile(path, file, ()=>{})
    )

    return Promise.all(promises)
}