// import '@fontsource/konkhmer-sleokchher';
// Supports weights 100-900
// import '@fontsource-variable/inter';
import { extendTheme, ThemeConfig } from '@chakra-ui/react'
// import {  MultiSelectTheme } from 'chakra-multiselect'
import '@fontsource/open-sans'
import "@fontsource/inter";

export default extendTheme({
    radii: {
      sm: "2px",
      md: "4px",
      lg: "8px",
      xl: "16px",
    },
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "3.75rem",
        "7xl": "4.5rem",
        "8xl": "6rem",
        "9xl": "8rem",
      },
    initialColorMode: 'light',
    useSystemColorMode: false,
    styles: {
        global: (props) => ({
            '::-webkit-scrollbar': {
                width: '6px',
            },
            '::-webkit-scrollbar-track': {
                background: 'transparent',
            },
            '::-webkit-scrollbar-thumb': {
                background: props.colorMode === 'dark' ? '#334155' : '#e2e8f0',
                borderRadius: '10px',
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: props.colorMode === 'dark' ? '#475569' : '#cbd5e1',
            },
            /* Firefox */
            'scrollbarWidth': 'thin',
            'scrollbarColor':
                props.colorMode === 'dark' ? '#555555 transparent' : 'lightgray transparent',
        }),
    },
    components: {
        Button: {
            variants: {
                solid: (props) => ({
                    bg: `${props.colorScheme}.500`,
                    _hover: {
                        bg: `${props.colorScheme}.600`, // Default hover behavior
                    },
                }),
            },
        },
        // MultiSelect: MultiSelectTheme
    },
    fonts: {
        heading: `Inter, sans-serif`,
        body: `Inter, sans-serif`,
    },

    // colors are from TailwindCSS: https://tailwindcss.com/docs/customizing-colors
    colors: {
        primary: {
            // these are blue
            // 50: '#EBF8FF',
            // 100: '#BEE3F8',
            // 200: '#90CDF4',
            // 300: '#63B3ED',
            // 400: '#4299E1',
            // 500: '#3182CE',
            // 600: '#2B6CB0',
            // 700: '#2C5282',
            // 800: '#2A4365',
            // 900: '#1A365D',

            // Violet
            50: "#f5f3ff",
            100: "#ede9fe",
            200: "#ddd6fe",
            300: "#c4b5fd",
            400: "#a78bfa",
            500: "#8b5cf6",
            600: "#7c3aed",
            700: "#6d28d9",
            800: "#5b21b6",
            900: "#4c1d95",
            950: "#2e1065"
        },
        slate: {
            50: "#f8fafc",
            100: "#f1f5f9",
            200: "#e2e8f0",
            300: "#cbd5e1",
            400: "#94a3b8",
            500: "#64748b",
            600: "#475569",
            700: "#334155",
            800: "#1e293b",
            900: "#0f172a",
            950: "#020617",
        },
    }
  }) as ThemeConfig;