
import { Box, Card, CardBody, Center, Flex, FormLabel, Radio, RadioGroup, Spinner, Stack, Switch } from "@chakra-ui/react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams, useNavigate } from "react-router-dom";
import useCourseConversations from "../../../hooks/useCourseConversations";
import useSingleCourse from "../../../hooks/useSingleCourse";
import useUserDoc from "../../../hooks/useUserDoc";
import { NUM_ADMIN_CONVERSATIONS_TO_FETCH } from "../../../lib/CONSTANTS";
import { auth } from "../../../lib/firebase";
import { updateCourseControl } from "../../../lib/database";

const SECURITY_PROMPTS_DESC_MAP = {
    1: "ATA will provide conceptual-only help (no code). Student questions similar to those found on conceptual homeworks will be deflected.",
    2: "ATA will provide conceptual-only help (no code). No conceptual question will be deflected.",
    3: "ATA will respond to any reasonable question related to a resource"
}

export default function Controls() {
    const [user, userLoading, userError] = useAuthState(auth)
    const params = useParams()

    const [course, courseLoading] = useSingleCourse(params.courseId)

    if (courseLoading) return <Card w='full'><CardBody><Center><Spinner /></Center></CardBody></Card>

    return (
        <Flex gap='3' flexDir={'column'}>
            <Flex alignItems={"center"}>
                <Switch
                    id="pastingDisabled"
                    isChecked={course.controls.pastingDisabled}
                    colorScheme={'primary'}
                    onChange={(e) => updateCourseControl(params.courseId, 'pastingDisabled', e.target.checked)}
                />
                <FormLabel ml="1" mb='0' fontSize={"lg"} htmlFor="pastingDisabled" cursor={'pointer'} userSelect={'none'}>
                    Students <strong>cannot</strong> paste into the query box
                </FormLabel>
            </Flex>

            <Flex alignItems={"center"}>
                <Switch
                    id="paused"
                    isChecked={course.controls.paused}
                    colorScheme={'primary'}
                    onChange={(e) => updateCourseControl(params.courseId, 'paused', e.target.checked)}
                />
                <FormLabel ml="1" mb='0' fontSize={"lg"} htmlFor="paused" cursor={'pointer'} userSelect={'none'}>
                    ATA is paused (students will not have access)
                </FormLabel>
            </Flex>

            <Flex alignItems={"center"}>
                <Switch
                    id="anonymizeStudents"
                    colorScheme={'primary'}
                    isChecked={course.controls.anonymizeStudents}
                    onChange={(e) => updateCourseControl(params.courseId, 'anonymizeStudents', e.target.checked)}
                />
                <FormLabel ml="1" mb='0' fontSize={"lg"} htmlFor="anonymizeStudents" cursor={'pointer'} userSelect={'none'}>
                    Students are anonymized
                </FormLabel>
            </Flex>

            <PromptLevelRadioGroup
                level={course.controls?.securityLevel ? course.controls.securityLevel.toString() : '2'}
                onUpdate={(v) => updateCourseControl(params.courseId, 'securityLevel', v)}
            />

        </Flex>
    )
}


function PromptLevelRadioGroup({ onUpdate, level }) {
    return (
        <Box>
            <FormLabel fontSize={'lg'} fontWeight={'bold'}>Security Level</FormLabel>
            <RadioGroup colorScheme="primary" onChange={(v) => onUpdate(parseInt(v))} value={level}>
                <Stack direction='column'>
                    {Object.entries(SECURITY_PROMPTS_DESC_MAP).map(([level, desc], i) => <Radio fontSize={'lg'} value={level} key={i}>{desc}</Radio>)}
                </Stack>
            </RadioGroup>
        </Box>
    )
}
