import { Alert, AlertIcon, AlertTitle, Box, Button, Center, FormLabel, Heading, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, Textarea, useToast } from "@chakra-ui/react";
import UserTable from "./UserTable";
import { useParams } from "react-router-dom";
import useCourseUsers from "../../../hooks/useCourseUsers";
import { useState } from "react";
import { isValidEmail } from "../../../lib/text_utils";
import { addEmailsToUserList } from "../../../lib/database";
import useCourseAllowedUsersList from "../../../hooks/useCourseAllowedUsersList";
import { useAuthState } from "react-firebase-hooks/auth";
import useUserDoc from "../../../hooks/useUserDoc";
import useSingleCourse from "../../../hooks/useSingleCourse";
import { auth } from "../../../lib/firebase";


function generateJoinLink(courseId: string) {
    return `https://talktoata.com/join/${courseId}`
}

export default function Users() {
    const params = useParams()
    const toast = useToast()
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
    const [isAllowedUsersListModalOpen, setIsAllowedUsersListModalOpen] = useState(false)

    const [user, userLoading, userError] = useAuthState(auth)
    const [adminUserDoc, isAdminUserDoc] = useUserDoc(user)
    const [course, isCourseLoading, extraCourseData] = useSingleCourse(params.courseId)

    const [users, isUsersLoading] = useCourseUsers(params.courseId)
    const [adminLevels, adminLevelsLoading] = useCourseAllowedUsersList(params.courseId)

    const copyJoinLink = () => {
        navigator.clipboard.writeText(generateJoinLink(params.courseId)).then(() => {
            toast({
                title: 'Join link copied to clipboard!',
                position: 'top',
                status: 'success',
                duration: 5000,
                isClosable: true
            })
        }).catch(err => {
            console.error('Failed to copy: ', err)
        })
    }

    const onAddUser = async (emails: string[]) => {
        setIsAddUserModalOpen(false)
        await addEmailsToUserList(params.courseId, emails)
        toast({
            title: `${emails.length} emails added to allowed user list`,
            description: `Send them the join link (talktoata.com/join/${params.courseId}) for them to join the course!`,
            position: "top",
            status: "success",
            duration: 5000,
            isClosable: true,
        })
    }

    const usersWithAtLeastOneQuery = isUsersLoading ? undefined : users.reduce((acc, { totalExchanges }) => totalExchanges > 0 ? acc + 1 : acc, 0)
    const usersWithAtLeastFiveQueries = isUsersLoading ? undefined : users.reduce((acc, { totalExchanges }) => totalExchanges > 4 ? acc + 1 : acc, 0)

    const anonymize = isCourseLoading || !isAdminUserDoc || adminUserDoc.adminLevels[params.courseId] <= 1 || course.controls.anonymizeStudents

    return (
        <Box>
            <HStack alignItems={'center'} justifyContent={'space-between'}>
                <Heading mb='3'>Users</Heading>
                <HStack>
                    <Button size='sm' fontWeight={'400'} variant='ghost' onClick={copyJoinLink}>Copy Join Link</Button>
                    <Button size='sm' fontWeight={'400'} variant={'ghost'} onClick={() => setIsAllowedUsersListModalOpen(true)}>User List</Button>
                    <Button size='sm' fontWeight={'400'} colorScheme={'primary'} onClick={() => setIsAddUserModalOpen(true)}>Add User</Button>
                </HStack>
            </HStack>

            <Text mb='1'><strong>Total Users:</strong> {isUsersLoading ? <Spinner size={'sm'} /> : users.length}</Text>
            <Text mb='1'><strong>Users with 1+ Queries:</strong> {usersWithAtLeastOneQuery === undefined ? <Spinner size={'sm'} /> : usersWithAtLeastOneQuery}</Text>
            <Text mb='3'><strong>Users with 5+ Queries:</strong> {usersWithAtLeastFiveQueries === undefined ? <Spinner size={'sm'} /> : usersWithAtLeastFiveQueries}</Text>

            {isUsersLoading ? <Center py='64'><Spinner /></Center> : <UserTable anonymize={anonymize} users={users} courseId={params.courseId} />}

            <AddUserModal
                isOpen={isAddUserModalOpen}
                onClose={() => setIsAddUserModalOpen(false)}
                onAddUser={onAddUser}
            />

            <AllowedUsersListModal
                isOpen={isAllowedUsersListModalOpen}
                onClose={() => setIsAllowedUsersListModalOpen(false)}
                adminLevels={adminLevels ?? {}}
            />
        </Box>
    )
}

function AllowedUsersListModal({ isOpen, onClose, adminLevels }: { isOpen: boolean, onClose: () => void, adminLevels: { [key: string]: number } }) {
    const levelToUsers: { [key: number]: string[] } = {}
    for (const [email, level] of Object.entries(adminLevels)) {
        if (Object.keys(levelToUsers).includes(level.toString())) {
            levelToUsers[level].push(email)
        } else {
            levelToUsers[level] = [email]
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>User Lists</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {Object.entries(levelToUsers).map(([level, users], i) => <><Heading size='lg' my='2' key={i}>Level {level}</Heading>{users.map((s, j) => <Text key={`${i}-${j}`} fontSize='sm'>{s}</Text>)}</>)}
                </ModalBody>
            </ModalContent>

        </Modal>
    )
}

function AddUserModal({ isOpen, onClose, onAddUser }) {

    const [emailsText, setEmailsText] = useState('')
    const [error, setError] = useState("")

    const onAddClick = () => {
        const emails = emailsText.trim().split(/[\n,]+/) // split on \n or ,

        for (const e of emails) {
            if (!isValidEmail(e)) {
                setError(`'${e}' is not a valid email`)
                return
            }

        }

        onAddUser(emails)
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Add Users</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormLabel><strong>User Emails</strong> - Enter one or more emails separated by commas or newlines</FormLabel>
                    <Textarea _focus={{ borderColor: 'primary.500', boxShadow: 'none', borderWidth: '2px' }} value={emailsText} placeholder="john@brown.edu" onChange={e => setEmailsText(e.target.value)} />

                    {error && <Alert my={'3'} status='error'>
                        <AlertIcon />
                        <AlertTitle>{error}</AlertTitle>
                    </Alert>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onAddClick} fontWeight={'400'} colorScheme={'primary'}>Add</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}