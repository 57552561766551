import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Flex, Heading, HStack, Select, Spinner, Text, VStack } from "@chakra-ui/react";
import ConversationTable from "./ConversationTable";
import { useParams } from "react-router-dom";
import useCourseConversations from "../../../hooks/useCourseConversations";
import useSingleCourse from "../../../hooks/useSingleCourse";
import { NUM_ADMIN_CONVERSATIONS_TO_FETCH } from "../../../lib/CONSTANTS";
import { getResourceList } from "../../resources";
import { ExtraCourseData } from "../../../types/types";
import { useEffect, useState } from "react";
import MultiToggle from "../../../ui/MultiToggle";


export default function Conversations() {
    const [filterResourceClass, setFilterResourceClass] = useState("all")
    const [filterResource, setFilterResource] = useState("all")

    const params = useParams()

    const [course, isCourseLoading, extraCourseData] = useSingleCourse(params.courseId, true)

    const [convos, totalConversationCount, isLoadingConversations, fetchMoreConvos] = useCourseConversations(params.courseId, NUM_ADMIN_CONVERSATIONS_TO_FETCH)

    useEffect(() => {
        if (!filterResourceClass) return

        setFilterResource("all")
    }, [filterResourceClass])

    if (isCourseLoading) return <Center py='64'><Spinner /></Center>

    const resourceClasses = Object.keys(course.schema)
    const resources = getResourceList(course.resources, extraCourseData as ExtraCourseData)
    const filterResources = filterResourceClass ? resources.filter(({ resourceClass }) => resourceClass == filterResourceClass).map(({ title }) => title) : []

    let convosToShow = convos
    if (filterResourceClass != "all") {
        convosToShow = convosToShow.filter(({ resourceClass }) => resourceClass === filterResourceClass)
    }
    if (filterResource != "all") {
        convosToShow = convosToShow.filter(({ resourceName }) => resourceName === filterResource)
    }

    return (
        <Box>
            <Heading mb='3'>Conversations</Heading>

            <Accordion allowToggle mb='6'>
                <AccordionItem>
                    <AccordionButton>
                        <Box as='span' flex='1' textAlign='left'>
                            Filter
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Heading mb='1' size='sm'>Resource Class</Heading>
                        <MultiToggle
                            allowMultipleOn={false}
                            items={["all", ...resourceClasses]}
                            onChange={(arr) => setFilterResourceClass(arr[0])}
                            mb='3'
                        />

                        { filterResourceClass != "all" && <><Heading mb='1' size='sm'>Resource</Heading>
                        <MultiToggle
                            allowMultipleOn={false}
                            items={["all", ...filterResources]}
                            onChange={(arr) => setFilterResource(arr[0])}
                            mb='6'
                            small
                        /></>}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>



            <ConversationTable
                renderUser={!course?.controls.anonymizeStudents}
                convos={convosToShow}
                courseId={params.courseId}
                name="all_conversation_table"
            />
            <Flex mt='3' flexDir={'row'} justifyContent={'center'} alignItems={'center'} gap={3}>
                <Text size='lg'>Showing {convos.length} / {totalConversationCount} conversations. </Text>
                {(convos.length < totalConversationCount && !isLoadingConversations) && <Text onClick={fetchMoreConvos} cursor={'pointer'} fontWeight={400} textDecoration={'underline'} _hover={{ textDecoration: 'none' }} variant={'link'}>Fetch more conversations</Text>}
                { isLoadingConversations && <Spinner /> }
            </Flex>
        </Box>
    )
}
