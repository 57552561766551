import dayjs, { Dayjs } from "dayjs";
import { Timestamp } from "firebase/firestore";
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(minMax)

const SHORT_DATE_FORMAT = "MMM D"
const LONG_DATE_FORMAT = "MMMM D, YYYY"
const WEEK_DATE_FORMAT = "ddd, MMM D"
const SHORT_DATE_FORMAT_NUMERICAL = "MM-DD-YYYY"
const TIME_FORMAT = "h:mma"
const HOUR_FORMAT = "ha"

// takes in a date object and formats it 
export function formatDateShort(date: Date | number | Dayjs | string) {
    if (typeof (date) == 'number') date = dayjs.unix(date)
    return dayjs(date).format(SHORT_DATE_FORMAT)
}

export function formatDateInWeek(date: Date | number | Dayjs | string) {
    if (typeof (date) == 'number') date = dayjs.unix(date)
    return dayjs(date).format(WEEK_DATE_FORMAT)
}
export function formatDateLong(date: Date | number | Dayjs | string) {
    if (typeof (date) == 'number') date = dayjs.unix(date)
    return dayjs(date).format(LONG_DATE_FORMAT)
}

export function formatDateShortNumerical(date: Date | number | Dayjs | string) {
    if (typeof (date) == 'number') date = dayjs.unix(date)
    return dayjs(date).format(SHORT_DATE_FORMAT_NUMERICAL)
}

export function formatTime(date) {
    return dayjs(date).format(TIME_FORMAT)
}

export function getToday() {
    return dayjs().toDate()
}

export function formatHour(date) {
    return dayjs(date).format(HOUR_FORMAT)
}

export function getTimestamp(date?: Date) {
    return dayjs(date).toDate().toISOString()
}

// returns true if dateOne is before dateTwo
export function compareDates(dateOne: Date | number | Dayjs | string, dateTwo: Date | number | Dayjs | string, utc = false) {
    if (!dateOne) return true
    if (!dateTwo) return false

    if (utc) {
        dateOne = dayjs.utc(dateOne)
        dateTwo = dayjs.utc(dateTwo)
    }

    return dayjs(dateTwo).isBefore(dateOne)
}

export function formatISO(date: Date) {
    return dayjs(date).toISOString()
}

export function getRelativeTimeString(date, utc = false) {
    let inputDate
    if (utc) {
        inputDate = dayjs.utc(date)
    } else {
        inputDate = dayjs(date)
    }
    const now = dayjs()
    return inputDate.from(now)
}

export function getLastXDays(x: number, startDate = undefined) {
    const dates = [dayjs(startDate).subtract(x - 1, 'day')]
    for (let i = 0; i < (x - 1); i++) {
        dates.push(dates[dates.length - 1].add(1, 'day'))
    }
    return dates
}

export function minDate(...dates: Date[] | Dayjs[]) {
    dates = dates.map(dayjs)
    return dayjs.min(dates)
}

export function getEndOfWeek(date = undefined) {
    const d = dayjs(date).endOf('week')
    return d
}

export function getDaysInBetween(dateOne, dateTwo) {
    return dayjs(dateOne).diff(dayjs(dateTwo), "days")
}

export function getMinutesSince(date) {
    return dayjs().diff(date, 'minute')
}

export function getSecondsSince(date) {
    return dayjs().diff(date, 'second')
}