import { Card, CardBody, CardFooter, HStack, Tag, useColorModeValue, Text, Heading, Button, Box, Icon, VStack, UnorderedList, ListItem, Spinner, useToast } from "@chakra-ui/react";
import { ResourceMetadata } from "../../../types/types";
import { RCLASS_TO_COLOR } from "../../../lib/CONSTANTS";
import { CalendarIcon, Check, CircleX, File, ListIcon } from "lucide-react";
import { formatDateShort } from "../../../lib/date_utils";
import useMap from "../../../hooks/useMap";
import { useEffect, useState } from "react";
import { listSubpathsOfSubDirs } from "../../../lib/storage";
import { capitalize } from "lodash";
import { getMaterialFilesIngestedForResource, sendIngestRequest } from "../add_resource/requests";
import { DeleteRequest } from "../add_resource/types";
import { useNavigate } from "react-router-dom";


export default function ResourceEntry({ title, releaseDate, resourceClass, dueDate, notePrompt, isInvisible, retrieveLinks, courseId, resourceTypes }: ResourceMetadata & { courseId: string, resourceTypes: string[] }) {
    const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false)
    const toast = useToast()
    const navigate = useNavigate()

    const handleEdit = () => {
        // navigate to edit page
        const path = `/app/${courseId}/admin/resources/update?resourceClass=${resourceClass}&resourceName=${encodeURIComponent(title)}`
        navigate(path)
    }

    const handleDelete = async () => {
        setDeleteInProgress(true)

        const deleteReq: DeleteRequest = {
            courseId,
            resourceClass,
            resourceName: title
        }

        try {
            await sendIngestRequest(deleteReq, "delete")
            toast({
                title: `'${title}' successfully deleted`,
                position: "top",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            console.error(error)
            setDeleteInProgress(false)
            toast({
                title: `'${title}' could not be deleted: '${error}'`,
                position: "top",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    }

    return (
        <Card
            variant="outline"
            borderRadius={2}
            bg={useColorModeValue('slate.100', 'slate.800')}
            borderColor={useColorModeValue('slate.200', 'slate.700')}
            borderWidth={'2px'}
        >
            <CardBody as={VStack} gap='3' alignItems={'stretch'}>
                <HStack alignItems={'center'} justifyContent={'space-between'}>
                    <Heading fontSize={'lg'}>{title}</Heading>
                    <Tag colorScheme={RCLASS_TO_COLOR[resourceClass]}>{resourceClass}</Tag>
                </HStack>

                <HStack>
                    <Icon>
                        <CalendarIcon />
                    </Icon>
                    <Text fontWeight="400">
                        {dueDate ? `${formatDateShort(releaseDate)} - ${formatDateShort(dueDate)}` : formatDateShort(releaseDate)}
                    </Text>
                </HStack>

                <NotePrompt notePrompt={notePrompt} />

                <Text>Invisible: {isInvisible ? 'Yes' : 'No'}</Text>

                <RetrieveLinkList retrieveLinks={retrieveLinks} />

                <FileList
                    courseId={courseId}
                    resourceClass={resourceClass}
                    resourceName={title}
                    resourceTypes={resourceTypes}
                />

            </CardBody>
            <CardFooter display={'flex'} gap='3' alignItems={'center'}>
                <Button onClick={handleEdit} color='white' fontWeight={'400'}>Edit</Button>
                <Button
                    onClick={!deleteInProgress ? handleDelete : undefined}
                    fontWeight={'400'}
                    colorScheme="red"
                    color='white'
                >
                    {deleteInProgress ? <Spinner /> : 'Delete'}
                </Button>
            </CardFooter>
        </Card>
    )
}

function NotePrompt({ notePrompt }) {
    if (!notePrompt) return

    return (
        <Box>
            <Text fontWeight={'bold'}>Note Prompt</Text>
            <Text>{notePrompt}</Text>
        </Box>
    )
}

function RetrieveLinkList({ retrieveLinks }) {
    if (!retrieveLinks) return

    return (
        <Box>
            <Text>Retrieve Links</Text>
            <UnorderedList>
                {retrieveLinks.map(({ rclass, rname }, i) => <ListItem key={i}>{rclass}/{rname}</ListItem>)}
            </UnorderedList>
        </Box>
    )
}

function FileList({ courseId, resourceClass, resourceName, resourceTypes, }: { courseId: string, resourceClass: string, resourceName: string, resourceTypes: string[] }) {
    const [resourceTypeToFiles, clear, updateResourceTypeFiles] = useMap<string[]>(Object.fromEntries(resourceTypes.map(rt => [rt, []])))
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchFiles()
    }, [])


    const fetchFiles = async () => {
        const resourceTypeToFilenames = await getMaterialFilesIngestedForResource(courseId, resourceClass, resourceName, resourceTypes)

        for (const resourceType of resourceTypes) {
            updateResourceTypeFiles(resourceType, resourceTypeToFilenames[resourceType])
        }

        setLoading(false)
    }

    if (loading) return <Box><Text>Files</Text><Spinner /></Box>

    return (
        <Box>
            {Object.entries(resourceTypeToFiles).map(([resourceType, files], i) =>
                <Box key={i}>
                    <Text fontWeight={'bold'}>{capitalize(resourceType)}</Text>
                    <VStack alignItems={'stretch'}>
                        {files.map((f, j) => <FileTag fileName={f} key={`${i}-${j}`} />)}
                    </VStack>
                </Box>
            )}
        </Box>
    )
}

function FileTag({ fileName, fileSize }: { fileName: string, fileSize?: number }) {
    const fileSizeString = fileSize !== undefined ? `(${(fileSize / 1024).toFixed(1)} KB)` : ''

    return (
        <HStack p={2} bg={useColorModeValue('white', 'slate.900')} borderRadius="md" >
            <Icon>
                <File />
            </Icon>
            <Text flex="1" noOfLines={1}>
                {fileName} {fileSizeString}
            </Text>
        </HStack >
    )
}