import { VStack, FormControl, FormLabel, Text, Input, Box, Checkbox, Button, Textarea, InputGroup, useColorModeValue, Card, CardBody, CardHeader, Heading, HStack, IconButton, useToast } from "@chakra-ui/react"
import { useEffect, useReducer, useRef, useState } from "react"
import MultiToggle, { MultiToggleControlled } from "../../../ui/MultiToggle"
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons"
import { capitalizeWords, isAlphanumericOrComma } from "../../../lib/text_utils"
import { Course, ExtraCourseData } from "../../../types/types"
import { DeleteFileRecord, FileUpdateRecord, ResourceFormResult, RetrieveLink, UploadFileRecord } from "./types"
import useMap from "../../../hooks/useMap"
import useURLParams from "../../../hooks/useURLParams"
import { listSubpathsOfSubDirs } from "../../../lib/storage"
import { getMaterialFilesIngestedForResource } from "./requests"

function formatDateForInput(date: Date) {
    return date.toISOString().slice(0, 16) // Trims to "YYYY-MM-DDTHH:mm"
}

export default function ResourceForm({ courseId, resourceClassToResourceTypes, courseResources, parsedData, onSubmit }: {
    courseId: string,
    resourceClassToResourceTypes: Course["schema"],
    courseResources: Course["resources"],
    parsedData: ExtraCourseData
    onSubmit: (r: ResourceFormResult) => void
}) {
    const toast = useToast()

    const [urlParams, urlParamsLoading] = useURLParams()

    const [resourceName, setResourceName] = useState<string>('')
    const [resourceNameError, setResourceNameError] = useState<string>('')
    const [resourceClass, setResourceClass] = useState<string>(Object.keys(resourceClassToResourceTypes)[0])
    const [retrieveLinks, setRetrieveLinks] = useState<RetrieveLink[]>([])
    const [releaseDate, setReleaseDate] = useState<string>('')
    const [notePrompt, setNotePrompt] = useState<string>('')
    const [isDueDate, setIsDueDate] = useState<boolean>(false)
    const [dueDate, setDueDate] = useState<string>('')
    const [isInvisibleResource, setIsInvisibleResource] = useState<boolean>(false)
    const [isUpdate, setIsUpdate] = useState<boolean>(false)

    const [resourceTypeToExistingPaths, clearAllResourceTypeExistingPaths, updateResourceTypeExistingPaths] = useMap<string[]>()
    const [resourceTypeToFileUpdateRecords, clearAllResourceTypeFileUpdateRecords, updateResourceTypeFileUpdateRecords] = useMap<FileUpdateRecord[]>()

    useEffect(() => {
        if (!urlParamsLoading && urlParams.has("resourceClass") && urlParams.has("resourceName")) {
            const resourceClass = decodeURIComponent(urlParams.get("resourceClass"))
            const resourceName = decodeURIComponent(urlParams.get("resourceName"))
            populateFormWithResource(resourceClass, resourceName)
        }
    }, [urlParams, urlParamsLoading])

    const populateFormWithResource = async (resourceClass: string, resourceName: string) => {
        try {
            const resourceRetrievalData = courseResources[resourceClass][resourceName]
            const resourceParsedData = parsedData[resourceClass][resourceName]

            setResourceName(resourceName)
            handleResourceClassChange(resourceClass) // also populates resourceTypeToUpdateRecords with empty lists

            // dates
            setReleaseDate(formatDateForInput(new Date(resourceParsedData.releaseDate)))
            if (resourceParsedData?.dueDate) setDueDate(formatDateForInput(new Date(resourceParsedData.dueDate)))

            // retrieve links
            if (Object.keys(resourceRetrievalData).includes("retrieve_links")) {
                setRetrieveLinks(resourceRetrievalData.retrieve_links as RetrieveLink[])
            }

            // note prompt
            if (Object.keys(resourceRetrievalData).includes("note_prompt")) {
                setNotePrompt(resourceRetrievalData.note_prompt)
            }

            // files
            const resourceTypeToFileNames = await getMaterialFilesIngestedForResource(courseId, resourceClass, resourceName, resourceClassToResourceTypes[resourceClass])
            for (const [resourceType, fileNames] of Object.entries(resourceTypeToFileNames)) {
                updateResourceTypeExistingPaths(resourceType, fileNames)
            }

            setIsUpdate(true)
        } catch (error) {
            console.error(`Couldn't populate form with resourceClass '${resourceClass}' and resourceName '${resourceName}':`, error)
        }
    }

    const handleResourceClassChange = (rc: string) => {
        clearAllResourceTypeFileUpdateRecords()
        clearAllResourceTypeExistingPaths()

        const resourceTypes = resourceClassToResourceTypes[rc]
        for (const resourceType of resourceTypes) {
            updateResourceTypeFileUpdateRecords(resourceType, [])
            updateResourceTypeExistingPaths(resourceType, [])
        }

        setResourceClass(rc)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (resourceNameError != "") {
            toast({
                title: 'Please fix the inputs before submitting',
                position: 'top',
                status: 'warning',
                description: resourceNameError,
                isClosable: true,
                duration: 5000
            })
            return
        }

        // TODO: validate that at least one file or retrieve link will be there after updates

        const result: ResourceFormResult = {
            releaseDate: new Date(releaseDate),
            resourceClass,
            resourceName,
            retrieveLinks: structuredClone(retrieveLinks),
            dueDate: isDueDate ? new Date(dueDate) : undefined,
            resourceTypeToFileUpdateRecords: structuredClone(resourceTypeToFileUpdateRecords),
            notePrompt,
            isInvisible: isInvisibleResource,
            isUpdate
        }
        onSubmit(result)
        clearInputs()
    }

    const clearInputs = () => {
        setResourceName("")
        setReleaseDate("")
        setIsDueDate(false)
        setDueDate("")
        handleResourceClassChange(resourceClass) // clears the files, keeps the same resource class
        setRetrieveLinks([])
        setNotePrompt("")
        setIsUpdate(false)
    }

    const handleResourceNameChange = (v: string) => {
        setResourceName(v)
        if (isAlphanumericOrComma(v)) {
            setResourceNameError('')
        } else {
            setResourceNameError('Please enter an alphanumeric resource name')
        }
    }

    const resourceClasses = Object.keys(resourceClassToResourceTypes)
    resourceClasses.sort()
    const resourceTypes = resourceClassToResourceTypes[resourceClass]

    return (
        <form onSubmit={handleSubmit}>
            <VStack gap='6' alignItems={'stretch'}>
                <FormControl isRequired id="resource-name">
                    <FormLabel>Resource Name</FormLabel>
                    <Input
                        type='text'
                        value={resourceName}
                        onChange={e => handleResourceNameChange(e.target.value)}
                        placeholder='Problem Set #1'
                    />
                    {resourceNameError != "" && <Text color="red.500">{resourceNameError}</Text>}
                </FormControl>

                <Box>
                    <FormLabel>Resource Class</FormLabel>
                    <ResourceClassInput
                        handleResourceClassChange={handleResourceClassChange}
                        resourceClass={resourceClass}
                        resourceClasses={resourceClasses}
                    />
                </Box>

                <SimpleDateInput
                    isRequired
                    label={"Release Date"}
                    onChange={setReleaseDate}
                    value={releaseDate}
                />

                <FormControl>
                    <Checkbox
                        isChecked={isDueDate}
                        onChange={(e) => setIsDueDate(e.target.checked)}
                        colorScheme={'primary'}
                    >
                        Set Due Date?
                    </Checkbox>
                </FormControl>

                <FormControl>
                    <Checkbox
                        isChecked={isInvisibleResource}
                        onChange={(e) => setIsInvisibleResource(e.target.checked)}
                        colorScheme={'primary'}
                    >
                        Is invisible resource? (to be used only via retrieve links)
                    </Checkbox>
                </FormControl>

                {isDueDate && <SimpleDateInput
                    isRequired={false}
                    label={"Due Date"}
                    onChange={setDueDate}
                    value={dueDate}
                />}

                <RetrieveLinksInput
                    retrieveLinks={retrieveLinks}
                    courseResources={courseResources}
                    setRetrieveLinks={setRetrieveLinks}
                />

                <FormControl id="note-prompt">
                    <FormLabel>Note for ATA (optional)</FormLabel>
                    <Textarea
                        value={notePrompt}
                        onChange={e => setNotePrompt(e.target.value)}
                        placeholder='Ex. Quote directly from the syllabus for grading-related queries'
                    />
                    {resourceNameError != "" && <Text color="red.500">{resourceNameError}</Text>}
                </FormControl>

                {resourceTypes.map((resourceType, i) =>
                    Object.keys(resourceTypeToFileUpdateRecords).includes(resourceType) &&
                    Object.keys(resourceTypeToExistingPaths).includes(resourceType) &&
                    <ResourceTypeFiles
                        key={i}
                        name={i}
                        fileUpdateRecords={resourceTypeToFileUpdateRecords[resourceType]}
                        existingPaths={resourceTypeToExistingPaths[resourceType]}
                        resourceType={resourceType}
                        setUpdateRecords={(records) => updateResourceTypeFileUpdateRecords(resourceType, records)}
                    />
                )}

                <Button type="submit" color='white' colorScheme="primary" fontWeight='400' width="full">
                    Submit
                </Button>
            </VStack>
        </form>
    )
}

function ResourceClassInput({ resourceClass, resourceClasses, handleResourceClassChange }: { resourceClass: string, resourceClasses: string[], handleResourceClassChange: (r: string) => void }) {

    const onChange = (activeItems: boolean[]) => {
        const newResourceClass = resourceClasses[activeItems.indexOf(true)]
        handleResourceClassChange(newResourceClass)
    }

    const activeItems = Array(resourceClasses.length).fill(false)
    activeItems[resourceClasses.indexOf(resourceClass)] = true

    return (
        <MultiToggleControlled
            activeItems={activeItems}
            setActiveItems={onChange}
            allowMultipleOn={false}
            items={resourceClasses}
            onChange={() => { }}
        />
    )
}

function RetrieveLinksInput({ courseResources, retrieveLinks, setRetrieveLinks }: { courseResources: Course["resources"], retrieveLinks: RetrieveLink[], setRetrieveLinks: (r: { rclass: string, rname: string }[]) => void }) {
    const options = []
    for (const [resourceClass, resources] of Object.entries(courseResources)) {
        for (const resourceName of Object.keys(resources)) {
            options.push(`${resourceClass}/${resourceName}`)
        }
    }
    options.sort()

    const onChange = (activeItems: boolean[]) => {
        const newRetrieveLinks = []
        for (let i = 0; i < activeItems.length; i++) {
            if (activeItems[i]) {
                newRetrieveLinks.push({ rclass: options[i].split("/")[0], rname: options[i].split("/")[1] })
            }
        }
        setRetrieveLinks(newRetrieveLinks)
    }

    if (options.length == 0) return

    const activeItems = Array(options.length).fill(false)
    for (const retrieveLink of retrieveLinks) {
        const option = `${retrieveLink.rclass}/${retrieveLink.rname}`
        activeItems[options.indexOf(option)] = true
    }

    return (
        <Box>
            <FormLabel>Retrieve Links (relevant resources to draw from)</FormLabel>
            {/* <MultiToggle
                items={options}
                allowMultipleOn
                onChange={onChange}
                small
                firstOn={false}
            /> */}
            <MultiToggleControlled
                activeItems={activeItems}
                setActiveItems={onChange}
                items={options}
                onChange={() => { }}
                allowMultipleOn
                small
            />
        </Box>
    )
}

const SimpleDateInput = ({
    label,
    value,
    onChange,
    isRequired
}) => {
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel>
                {label}
                {/* {!isRequired && <Button ml='2' fontWeight='400' variant='link' onClick={() => onChange(undefined)}>Clear</Button>}  */}
            </FormLabel>
            <InputGroup>
                <Input
                    type="datetime-local"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    bg={useColorModeValue('white', 'gray.800')}
                />
            </InputGroup>
        </FormControl>
    )
}

function ResourceTypeFiles({ resourceType, existingPaths, fileUpdateRecords, setUpdateRecords, name }: { resourceType: string, existingPaths: string[], fileUpdateRecords: FileUpdateRecord[], setUpdateRecords: (records: FileUpdateRecord[]) => void, name: string | number }) {
    return (
        <Card variant={'outline'}>
            <CardHeader>
                <Heading fontSize={'xl'}>{capitalizeWords(resourceType)}</Heading>
            </CardHeader>
            <CardBody>
                <FileUploadUI existingPaths={existingPaths} updateRecords={fileUpdateRecords} setUpdateRecords={setUpdateRecords} name={name} />
            </CardBody>
        </Card>
    )
}

function FileUploadUI({ existingPaths, updateRecords, setUpdateRecords, name }:
    {
        existingPaths: string[],
        updateRecords: FileUpdateRecord[]
        setUpdateRecords: (records: FileUpdateRecord[]) => void,
        name: string | number
    }) {
    const fileInputRef = useRef(null)

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files) as File[]
        const newUpdates: UploadFileRecord[] = files.map(f => ({ type: "UPLOAD", file: f }))
        setUpdateRecords([...updateRecords, ...newUpdates])
    }

    const removeFile = (fileName: string) => {
        // if fileName in existingPaths, add delete record
        if (existingPaths.includes(fileName)) {
            const deleteRecord: DeleteFileRecord = { type: "DELETE", fileName }
            setUpdateRecords([...updateRecords, deleteRecord])
        } else {
            // else, remove upload record
            setUpdateRecords(updateRecords.filter((r) => !("file" in r && r.file.name == fileName)))
        }
    }

    useEffect(() => {
        if (updateRecords.length === 0 && fileInputRef.current) {
            fileInputRef.current.value = "" // Clear the file input
        }
    }, [updateRecords, fileInputRef])


    const filesToShow: string[] = [...existingPaths] // represents the state of the files in storage & ingested after these updates would be carried out
    for (const record of updateRecords) {
        if (record.type == "UPLOAD") {
            filesToShow.push(record.file.name)
        } else { // DELETE
            const index = filesToShow.indexOf(record.fileName)
            filesToShow.splice(index, 1)
        }
    }

    return (
        <VStack spacing={4} alignItems={'start'} w='full'>
            <Button
                as="label"
                htmlFor={`file-upload-${name}`}
                leftIcon={<AttachmentIcon />}
                colorScheme="primary"
                cursor="pointer"
                color='white'
                fontWeight={'400'}
            >
                Select Files
                <Input
                    id={`file-upload-${name}`}
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    hidden
                    ref={fileInputRef}
                />
            </Button>

            <VStack align="stretch" maxH="300px" w='full' overflowY="auto">
                {filesToShow.map((filename, index) => (
                    <FileTag key={index} fileName={filename} removeFile={() => removeFile(filename)} />
                ))}
            </VStack>
        </VStack>
    )
}


function FileTag({ fileName, fileSize, removeFile = undefined }: { fileName: string, fileSize?: number, removeFile?: () => void }) {
    const fileSizeString = fileSize ? `(${(fileSize / 1024).toFixed(1)} KB)` : ''

    return (
        <HStack p={2} bg="slate.100" borderRadius="md" >
            <Text flex="1" noOfLines={1}>
                {fileName} {fileSizeString}
            </Text>
            {
                removeFile !== undefined && <IconButton
                    icon={<DeleteIcon />}
                    size="sm"
                    colorScheme="red"
                    onClick={removeFile}
                    aria-label="Remove file"
                />
            }
        </HStack >
    )
}