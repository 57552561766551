import { UserInfo } from "firebase/auth"
import { doc, DocumentReference, onSnapshot, setDoc } from "firebase/firestore"
import { useState, useEffect } from "react"
import { logEvent } from "firebase/analytics"
import { analytics, db } from "../lib/firebase"
import { User } from "../types/types"


const blankUserDoc: User = {
    uid: '',
    email: '',
    courses: [],
    exists: false,
    displayName: "",
    photoURL: "",
    adminLevels: {},
    notificationsSeen: 0
}

export default function useUserDoc(user: UserInfo) {
    const [userDoc, setUserDoc] = useState<User>(blankUserDoc)
    const [isUserDoc, setIsUserDoc] = useState(false)

    useEffect(() => {
        if (!user) {
            setUserDoc(blankUserDoc)
            setIsUserDoc(false)
            return
        }

        const userDocRef = doc(db, "Users", user.uid)

        const unsub = onSnapshot(userDocRef, (doc) => {
            const fromCache = doc.metadata.fromCache
            if (doc.exists()) {
                setUserDoc({ ...doc.data() as User, uid: user.uid, exists: true })
                setIsUserDoc(true)
                logEvent(analytics, "login")
            }
            else if (!fromCache) {
                createUserDoc(userDocRef, user.email, user.displayName, user.photoURL)

                // analytics
                logEvent(analytics, "sign_up")

                console.log("create userDoc")
            }
        })

        return () => unsub()
    }, [user])

    return [userDoc, isUserDoc] as [User, boolean]
}

function createUserDoc(userDocRef: DocumentReference, email: string, displayName: string, photoURL: string) {
    const userDoc: User = {
        email,
        displayName,
        photoURL,
        courses: [],
        adminLevels: {},
        notificationsSeen: 0
    }
    setDoc(userDocRef, userDoc)
}

function isValidEmail(email) {
    const whitelist = ['rabboud@schmidtfuturesprojects.org']
    const VALID_DOMAINS = ['brown.edu', 'alumni.brown.edu']
    const domain = email.split("@")[1]
    return VALID_DOMAINS.includes(domain) || whitelist.includes(email)
}