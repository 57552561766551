import { Box, Center, Heading, Stack, useToast, Skeleton, Button, useColorModeValue, Grid, useBreakpoint, VStack } from "@chakra-ui/react";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import Pair from "../tutor/Pair";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../lib/firebase";
import { loginUserWithPopup } from "../../lib/login";
import { useNavigate, useParams } from "react-router-dom";
import { useFirebaseRoutesAnalytics } from "../../hooks/useFirebaseRoutesAnalytics";
import useSingleConversation from "../../hooks/useSingleConversation";
import useUserDoc from "../../hooks/useUserDoc";
import useURLParams from "../../hooks/useURLParams";
import SourcesPane from "../tutor/SourcesPane";
import { MOBILE_BREAKPOINTS } from "../../lib/CONSTANTS";
import { ConvoAdminCard, TitleAndControls } from "../tutor";
import useStickyState from "../../hooks/useStickyState";
import { formatDateLong } from "../../lib/date_utils";

export default function Share({ }) {
    useFirebaseRoutesAnalytics()

    const toast = useToast()
    const navigate = useNavigate()
    const [isClient, setIsClient] = useState(false)
    const [isSigningIn, setIsSigningIn] = useState(false)

    const [user, userLoading, userError] = useAuthState(auth)
    const tutorResponseRefs = useRef([])
    const [userDoc, isUserDoc] = useUserDoc(user)
    const [displaySources, setDisplaySources] = useState(true)
    const [focusSourceId, setFocusSourceId] = useState(undefined)

    const params = useParams()
    const breakpoint = useBreakpoint()

    const [urlParams, urlParamsLoading] = useURLParams()

    const [conversationDoc, isInvalidId] = useSingleConversation(params.courseId, params.conversationId)
    // const isAdmin = isUserDoc ? userDoc.adminCourses.includes(params.courseId) : false
    // const [adminDisplay, setAdminDisplay] = useStickyState(isAdmin, "adminDisplay")

    const doRenderAnnotationLink = isUserDoc && userDoc.adminLevels[params.courseId] >= 3

    const dateColor = useColorModeValue('slate.700', 'slate.300')

    const [areSourcesToShow, setAreSourcesToShow] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    useEffect(() => {
        if (isInvalidId) {
            toast({
                title: "Invalid Link",
                description: "This shareable link appears to be invalid. Double-check the source you got it from.",
                position: 'top',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [isInvalidId])

    const onSignInButtonClick = () => {
        // user ? navigate('/tutor') : loginUserWithPopup()
        if (user) {
            navigate('/app/dashboard')
        } else {
            loginUserWithPopup()
            setIsSigningIn(true)
        }
    }

    useEffect(() => {
        if (isSigningIn && user) navigate('/app/dashboard')
    }, [user])


    useEffect(() => {
        if (urlParamsLoading) return

        if (urlParams.has("exchange") && tutorResponseRefs.current.length > 0) {
            const el = tutorResponseRefs.current[parseInt(urlParams.get("exchange"))]
            console.log("scrolling..", el)
            el.scrollIntoView({ behavior: 'smooth' })
        }
    }, [tutorResponseRefs.current, urlParams, urlParamsLoading])

    const handleReferenceTagClick = (id: string) => {
        setDisplaySources(true)
        setFocusSourceId(id)
    }

    const doShowUid = isUserDoc && userDoc.adminLevels[params.courseId] >= 1

    const sourcesRendered = displaySources && areSourcesToShow
    const renderChat = ((MOBILE_BREAKPOINTS.includes(breakpoint) && !sourcesRendered) || !MOBILE_BREAKPOINTS.includes(breakpoint))

    return (
        <Center flexDir={'column'} bg={useColorModeValue('slate.50', 'slate.800')} h='100vh'>
            {/* <Heading my='3' fontWeight={'400'} fontSize={'xl'}>{conversationDoc?.title}</Heading> */}
            <TitleAndControls
                areSourcesVisible={displaySources}
                conversationTitle={conversationDoc?.title}
                isAdmin={false}
                isAdminDisplayOn={false}
                onCopyShareableLink={() => { }}
                renderShareableLink={false}
                renderSourcesToggle={areSourcesToShow}
                resourceClass={conversationDoc?.resourceClass}
                renderAnnotationLink={doRenderAnnotationLink}
                annotationLink={`/app/${params.courseId}/admin/annotations/${params.conversationId}`}
                resourceName={conversationDoc?.resourceName}
                setAdminDisplay={() => { }}
                toggleSources={() => setDisplaySources(!displaySources)}
                isShareableLink
            />

            {conversationDoc && <Heading textAlign={'center'} my='3' fontSize={'xl'} color={dateColor}>{formatDateLong(conversationDoc.timestampLastUpdated.toDate())}</Heading>}

            <Grid px='6' gridTemplateColumns={(areSourcesToShow && displaySources) ? ['1fr', null, null, '1fr 1fr'] : '1fr'} w='full' h='full' overflowY={'hidden'}>
                {renderChat && (conversationDoc?.responses ? <Box
                    m='auto'
                    overflowY={"auto"}
                    // maxH='calc(85vh)'
                    h='full'
                    borderRadius={'lg'}
                    flex={1}
                    w='full'
                    // px='3'
                    // bg='white'
                    position={'relative'}
                    sx={{
                        "::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                    py={8}
                >
                    {isClient && <Stack gap={8}>
                        {conversationDoc?.responses.map((response, i) => <VStack m='auto' w='full' maxW='4xl' gap={8} key={i}>
                            <Pair
                                // userName={conversationDoc?.userName}
                                userName={doShowUid ? conversationDoc.userDocRef.id : "Anonymous"}
                                isShare
                                onReferenceTagClick={handleReferenceTagClick}
                                loadingMessage=""
                                tutorResponseRef={(el) => tutorResponseRefs.current[i] = el}
                                onCopyResponseLink={undefined}
                                {...response}
                                isAdmin={false}
                                onFeedback={console.log}
                            />
                        </VStack>)}
                        {/* {adminDisplay && <ConvoAdminCard m='auto' maxW='4xl' lastTutorEngineVersion={conversationDoc?.lastTutorEngineVersion} {...conversationDoc?.tutorState} />} */}
                    </Stack>}
                </Box> : <Skeleton h='calc(90vh)' m='auto' w={['sm', null, '2xl', '4xl']} />)}

                <SourcesPane
                    responses={conversationDoc?.responses}
                    setAreSourcesToShow={setAreSourcesToShow}
                    courseId={params.courseId}
                    styleProps={{ maxH: 'calc(85vh)' }}
                    onClose={() => setDisplaySources(false)}
                    title={conversationDoc?.resourceName}
                    display={displaySources}
                    focusSourceId={focusSourceId}
                />
            </Grid>
            {/* <Button
                colorScheme={'primary'}
                px='16'
                size='lg'
                my='3'
                fontWeight='300'
                onClick={onSignInButtonClick}
            >
                {user ? 'Use ATA' : 'Sign up to use ATA'}
            </Button> */}
        </Center>
    )
}