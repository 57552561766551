import { Accordion, AccordionButton, AccordionIcon, Link, AccordionItem, AccordionPanel, Box, Center, Heading, Tooltip, ListItem, Spinner, UnorderedList, Icon, Text, Flex } from "@chakra-ui/react";
import MultiToggle from "../../../ui/MultiToggle";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSingleCourse from "../../../hooks/useSingleCourse";
import { getResourceList } from "../../resources";
import { Cluster, ExtraCourseData } from "../../../types/types";
import useCourseClusters from "../../../hooks/useCourseClusters";
import { Link as ReactRouterLink } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { cutOffText } from "../../../lib/text_utils";

export default function Clusters() {
    const [filterResourceClass, setFilterResourceClass] = useState(undefined)
    const [filterResource, setFilterResource] = useState("all")

    const params = useParams()

    const [course, isCourseLoading, extraCourseData] = useSingleCourse(params.courseId, true)

    const [clusters, isClustersLoading] = useCourseClusters(params.courseId)

    useEffect(() => {
        if (isCourseLoading || filterResourceClass !== undefined) return
        const resourceClasses = Object.keys(course.schema)
        setFilterResourceClass(resourceClasses[0])
    }, [isCourseLoading, course])

    if (isCourseLoading || isClustersLoading || filterResourceClass === undefined) return <Center pt='32'><Spinner /></Center>

    const resourceClasses = Object.keys(course.schema)
    const resources = getResourceList(course.resources, extraCourseData as ExtraCourseData)
    const filterResources = filterResourceClass ? resources.filter(({ resourceClass }) => resourceClass == filterResourceClass).map(({ title }) => title) : []

    let clustersToShow: Cluster[] = []
    if (filterResource === "all") {
        clustersToShow = clusters.resourceClassClusters[filterResourceClass]
    } else {
        if(Object.keys(clusters.resourceClusters[filterResourceClass]).includes(filterResource)){
            clustersToShow = clusters.resourceClusters[filterResourceClass][filterResource]
        }
    }

    return (
        <Box>
            <Heading mb='3'>Clusters</Heading>
            <Box>
                <Heading mb='1' size='sm'>Resource Class</Heading>
                <MultiToggle
                    allowMultipleOn={false}
                    items={resourceClasses}
                    onChange={(arr) => setFilterResourceClass(arr[0])}
                    mb='3'
                />

                <Heading mb='1' size='sm'>Resource</Heading>
                <MultiToggle
                    allowMultipleOn={false}
                    items={["all", ...filterResources]}
                    onChange={(arr) => setFilterResource(arr[0])}
                    mb='6'
                    small
                />
            </Box>

            { clustersToShow.length === 0 && <Text>Insufficient conversations to compute clusters for this resource.</Text> }

            <Accordion allowMultiple>
                {clustersToShow.map((c, i) => <AccordionItem key={i}>
                    <AccordionButton display={'flex'} justifyContent={'space-between'}>
                        <ConversationLink {...c.topic} courseId={params.courseId} />
                        <Flex gap='2' alignItems={'center'}>
                            <Text>{c.conversations.length} conversations</Text>
                            <AccordionIcon />
                        </Flex>
                    </AccordionButton>
                    <AccordionPanel>
                        <UnorderedList spacing={'2'}>
                            { c.conversations.map((q, j) => <ListItem key={j}><ConversationLink {...q} courseId={params.courseId} /></ListItem>) }
                        </UnorderedList>
                    </AccordionPanel>
                </AccordionItem>)}
            </Accordion>

        </Box>
    )
}

function ConversationLink({ id, query, courseId }){

    const truncated = cutOffText(query, 100, true)

    return (
        <Link 
            as={ReactRouterLink} 
            gap='2' 
            display={'flex'} 
            textAlign={'left'}
            to={`/app/${courseId}/share/${id}`}
        >
            { truncated.length < query.length ? <Tooltip label={query}>{truncated}</Tooltip> : query }
            <Icon as={FaExternalLinkAlt} boxSize='3' color='slate.400'/>
        </Link>
    )
}