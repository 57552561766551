import { doc, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../lib/firebase"
import { AdminLevelsDoc } from "../types/types"


export default function useCourseAllowedUsersList(courseId: string) {
    const [adminLevels, setAdminLevels] = useState(undefined)
    const [isLoading, setIsLoading] = useState(true)

    const fetchUserList = () => {
        setIsLoading(true)
        const docRef = doc(db, "Courses", courseId, "Data", "users")

        const unsub = onSnapshot(docRef, snapshot => {
            const usersDoc = snapshot.data() as AdminLevelsDoc
            setAdminLevels(usersDoc.userAdminLevels)
            setIsLoading(false)
        })

        return unsub
    }

    useEffect(() => {
        return fetchUserList()
    }, [courseId])

    return [adminLevels, isLoading]
}