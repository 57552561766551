import { Timestamp } from "firebase/firestore"

export const FILTER_DECISIONS = ['SOCRATIC', 'INFO', 'MALICIOUS', 'OFFTOPIC']
// export const validActionDecisions = ['DEFLECT', 'SOCRATIC', 'INFORMATION', 'DIAGNOSTIC', 'HINT']
export type FilterDecision = 'SOCRATIC' | 'MALICIOUS' | 'OFFTOPIC' | 'INFO'
// export type ActionDecision = 'DEFLECT' | 'SOCRATIC' | 'INFORMATION' | 'DIAGNOSTIC' | 'HINT'

export type ResourceCount = {
    resourceClass: string
    resourceName: string
    queryCount: number
}

type Summary = {
    numExchanges: number
    numTotalConversations: number
    numConversationsStarted: number
    filterDecisionCounts: {
        [key in FilterDecision]: number
    }
    // actionCounts: {
    //     [key in ActionDecision]: number
    // }
    activeUIDs: string[]
    averageConversationLength: number
    averageExchangeCost: number
    numActiveUsers: number
    averageLatency: number
    resourceCounts: ResourceCount[]
    timestamp: Timestamp
}

export type HourSummary = Summary
export type DaySummary = Summary & { hourSummaries: HourSummary[], id: string }