
// wrap dollar signs around Latex
export function wrapLatex(text: string) {
    return text.replaceAll('\\[', "$")
        .replaceAll("\\]", "$")
        .replaceAll('\\(', "$")
        .replaceAll('\\)', "$")
        .replaceAll('·', "⋅") // first character gets re-written into \cdotp which errors inside \text{}
        .replaceAll('=\n', '=') // when an equal sign in LaTeX is given its own line, markdown says the line above is a header
}

// Cut off text at nearest word and (optionally) replace with '...' after n characters
export function cutOffText(text: string, n = 50, ellipsis = false) {
    const words = text.split(" ")
    let subText = " "
    let counter = 0
    let cutOff = false
    for (let i = 0; i < words.length; i++) {
        subText += ` ${words[i]}`
        counter += words[i].length + 1
        if (counter > n && i < words.length - 1) {
            cutOff = true
            break
        }
    }

    if (ellipsis && cutOff) return subText + "..."
    else return subText
}
export function extractCoT(text: string) {
    return text.substring(11, text.length - 15)
}

export function capitalizeWords(text: string) {
    return text.split(" ").map((s, _) => s[0].toUpperCase() + s.substring(1)).join(" ")
}

export function hashString(s: string) {
    let hash = 0
    for (const c of s.split('')) hash += 12 * c.charCodeAt(0)
    return hash
}

export function isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export function isAlphanumericOrComma(str: string) {
    return /^[a-zA-Z0-9,\s]*$/.test(str)
}