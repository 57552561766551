import { Card, CardBody, Icon, Text, Box, Heading } from "@chakra-ui/react"
import { formatISO } from "../../../lib/date_utils"
import { deleteExistingResourceFiles, deleteResourceFiles, sendIngestRequest, uploadResourceFiles } from "./requests"
import { AddOrUpdateRequest, DeleteFileRecord, IngestionServiceRequest, ResourceFormResult, UploadFileRecord } from "./types"
import { ArrowUpFromLine, CircleX, ScrollText, Check, LucideIcon, Coffee } from 'lucide-react';
import { capitalize } from "lodash";
import { useEffect, useState } from "react";

type Status = 'start' | 'getting ready' | 'uploading' | 'updating' | 'error' | 'success'

export default function IngestionTask({ courseId, formResult }: { courseId: string, formResult: ResourceFormResult }) {
    const [message, setMessage] = useState<string>('...')
    const [status, setStatus] = useState<Status>('start')

    useEffect(() => {
        if (status == 'start') {
            deleteMaterials()
        }
    }, [])

    const deleteMaterials = async () => {
        setStatus('getting ready')
        try {
            const resourceTypeToDeleteRecords: { [key: string]: DeleteFileRecord[] } = {}
            for (const [resourceType, records] of Object.entries(formResult.resourceTypeToFileUpdateRecords)) {
                resourceTypeToDeleteRecords[resourceType] = records.filter(({ type }) => type == "DELETE") as DeleteFileRecord[]
            }
            await deleteResourceFiles(
                courseId,
                formResult.resourceClass,
                formResult.resourceName,
                resourceTypeToDeleteRecords
            )

            uploadMaterials()
        } catch (error) {
            setStatus('error')
            setMessage(`Error occured while cleaning up: \`${error}\``)
        }
    }

    // upload resources
    const uploadMaterials = async () => {
        setStatus('uploading')
        try {
            const resourceTypeToUploadRecords: { [key: string]: UploadFileRecord[] } = {}
            for (const [resourceType, records] of Object.entries(formResult.resourceTypeToFileUpdateRecords)) {
                resourceTypeToUploadRecords[resourceType] = records.filter(({ type }) => type == "UPLOAD") as UploadFileRecord[]
            }
            await uploadResourceFiles(
                courseId,
                formResult.resourceClass,
                formResult.resourceName,
                resourceTypeToUploadRecords
            )
            updateResource()
        } catch (error) {
            setStatus('error')
            setMessage(`Error occured while uploading materials: \`${error}\``)
        }
    }

    const updateResource = async () => {
        setStatus('updating')
        try {
            // convert File objects to fileNames so we can send them to the service
            const resourceTypeToRecordsServer: AddOrUpdateRequest["resourceTypeToFileUpdateRecords"] = {}
            for (const [resourceType, records] of Object.entries(formResult.resourceTypeToFileUpdateRecords)) {
                resourceTypeToRecordsServer[resourceType] = records.map(
                    r => ({
                        type: r.type == "UPLOAD" ? "ADD" : "DELETE",
                        fileName: r.type == "UPLOAD" ? r.file.name : r.fileName
                    }))
            }

            const req: AddOrUpdateRequest = {
                ...formResult,
                resourceTypeToFileUpdateRecords: resourceTypeToRecordsServer,
                courseId,
                releaseDate: formatISO(formResult.releaseDate),
                dueDate: formResult.dueDate == undefined ? undefined : formatISO(formResult.dueDate)
            }
            await sendIngestRequest(req, 'add_or_update')
            setStatus('success')
        } catch (error) {
            setStatus('error')
            setMessage(`Error occured while ingesting: \`${error}\``)
        }
    }

    var LucideIcon: LucideIcon
    var color: string
    switch (status) {
        case "start":
        case "uploading":
            LucideIcon = ArrowUpFromLine
            color = 'blue'
            break
        case "getting ready":
            LucideIcon = Coffee
            color = "slate"
            break
        case "error":
            LucideIcon = CircleX
            color = 'red'
            break
        case "updating":
            LucideIcon = ScrollText
            color = 'purple'
            break
        case "success":
            LucideIcon = Check
            color = 'green'
    }

    return (
        <Card>
            <CardBody display={'flex'} alignItems={'center'} gap='3'>
                <Icon fontSize={'48px'} color={`${color}.500`}>
                    <LucideIcon />
                </Icon>
                <Box>
                    <Heading fontSize={'lg'}>{formResult.resourceName} - {capitalize(status)}</Heading>
                    <Text>
                        {message}
                        {status == 'error' && '. Email noah_rousell@brown.edu with the error message so he can fix this!'}
                    </Text>
                </Box>
            </CardBody>
        </Card>
    )
}
