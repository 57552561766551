import { Box, Button, Card, CardBody, CardFooter, CardHeader, Center, Flex, Heading, HStack, Icon, Spinner, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../lib/firebase";
import useUserDoc from "../../../hooks/useUserDoc";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import useUserDocFromUID from "../../../hooks/useUserDocFromUID";
import useUserConversations from "../../../hooks/useUserConversations";
import { NUM_ADMIN_CONVERSATIONS_TO_FETCH } from "../../../lib/CONSTANTS";
import ConversationTable from "../conversations/ConversationTable";
import { toggleUserBoot } from "../../../lib/database";
import { IoMdArrowBack } from "react-icons/io";
import useUserCourseDoc from "../../../hooks/useUserCourseDoc";
import useSingleCourse from "../../../hooks/useSingleCourse";


export default function User({ }) {

    const navigate = useNavigate()
    const { courseId, uid } = useParams()

    const [user, userLoading, userError] = useAuthState(auth)
    const [adminUserDoc, isAdminUserDoc] = useUserDoc(user)
    const [course, isCourseLoading, extraCourseData] = useSingleCourse(courseId)

    // TODO: add UsageChart (stacked bar chart showing filter ENGAGE vs. MALICIOUS/OFFTOPIC)
    const [userCourseData, isUserCourseData] = useUserCourseDoc(uid, courseId, isCourseLoading ? undefined : course.controls.quota.maxQueriesAccumulated)

    // get data on focus user (not admin)
    const [focusUserDoc, isFocusUserDoc] = useUserDocFromUID(uid)

    const [conversations, fetchNextPageOfUserConversations, _, __] = useUserConversations(courseId, uid, NUM_ADMIN_CONVERSATIONS_TO_FETCH)

    if (!isUserCourseData || isCourseLoading) return <Center py='32'><Spinner /></Center>

    const anonymize = isCourseLoading || !isAdminUserDoc || adminUserDoc.adminLevels[courseId] <= 1 || course.controls.anonymizeStudents

    return (
        <Box>
            <Box mb='6'>
                <BackButton to={`/app/${courseId}/admin/users`} />
            </Box>


            <HStack mb='6' alignItems={'center'} justifyContent={'space-between'}>
                <Heading size='lg'>{anonymize ? uid : focusUserDoc?.displayName}</Heading>
                {!anonymize && <Text color='slate.500'>{focusUserDoc?.email}</Text>}
            </HStack>

            {/* <Controls
                onBootUser={() => toggleUserBoot(uid, courseId, !focusUserDoc.bootedCourses.includes(courseId))}
                isUserBooted={focusUserIsBooted}
            /> */}

            <Numbers
                conversationsCreated={conversations.length}
                maliciousQueries={userCourseData.filterDecisionCounts.MALICIOUS}
                offTopicQueries={userCourseData.filterDecisionCounts.OFFTOPIC}
                mb='6'
            />

            {conversations.length > 0 ? <ConversationTable
                renderUser={false}
                convos={conversations}
                courseId={courseId}
                name="user_conversation_table"
            /> : <Text textAlign={'center'} py='16'>This user has 0 conversations.</Text>}
        </Box>

    )
}

function Controls({ onBootUser, isUserBooted }) {
    return (
        <Flex mb='6' flexDir={'row'} alignItems={'center'} gap='3'>
            <Button fontWeight={'400'} colorScheme={isUserBooted ? 'slate' : 'red'} onClick={onBootUser}>{isUserBooted ? 'Un-boot User' : 'Boot User'}</Button>
        </Flex>
    )
}

function BackButton(args) {
    const { to, ...otherArgs } = args

    return (
        <Link to={to}>
            <Button
                {...otherArgs}
                variant='link'
                leftIcon={<Icon as={IoMdArrowBack} />}
                fontWeight={400}
            >
                Back
            </Button>
        </Link>
    )
}

function Numbers({ conversationsCreated, maliciousQueries, offTopicQueries, ...otherArgs }) {
    return (
        <Card variant={'outline'} {...otherArgs}>
            <CardBody>
                <HStack gap='3'>
                    <Stat>
                        <StatLabel fontSize={'md'}>Conversations Created</StatLabel>
                        <StatNumber>{conversationsCreated}</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel fontSize={'md'}>Malicious Queries</StatLabel>
                        <StatNumber>{maliciousQueries}</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel fontSize={'md'}>Off-Topic Queries</StatLabel>
                        <StatNumber>{offTopicQueries}</StatNumber>
                    </Stat>
                </HStack>
            </CardBody>
        </Card>
    )
}