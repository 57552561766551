import { Box, Center, Grid, Heading, Spinner, useColorModeValue, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useSingleCourse from "../../../hooks/useSingleCourse";
import ResourceForm from "./ResourceForm";
import useList from "../../../hooks/useList";
import IngestionTask from "./IngestionTask";
import { ResourceFormResult } from "./types";
import { useEffect, useState } from "react";
import useURLParams from "../../../hooks/useURLParams";
import { ExtraCourseData } from "../../../types/types";


export default function AddResource() {
    const params = useParams()
    const [course, isCourseLoading, resourceParsedData] = useSingleCourse(params.courseId, true)

    const [urlParams, urlParamsLoading] = useURLParams()
    const [isUpdatingResource, setIsUpdatingResource] = useState<boolean>(false)

    const [
        ingestionTasks,
        addIngestionTask,
        _,
        __,
        ___,
        removeIngestionTaskAt
    ] = useList<ResourceFormResult>([])

    const handleSubmit = (r: ResourceFormResult) => {
        addIngestionTask(r)
        if (isUpdatingResource) {
            setIsUpdatingResource(false)
        }
    }

    useEffect(() => {
        if (!urlParamsLoading && urlParams.has("resourceClass") && urlParams.has("resourceName")) {
            setIsUpdatingResource(true)
        }
    }, [urlParams, urlParamsLoading])

    const ingestionTaskQueueBg = useColorModeValue('slate.50', 'slate.800')

    if (isCourseLoading) {
        return <Center pt='32'><Spinner /></Center>
    }

    return (
        <Grid h='full' maxW={'6xl'} m='auto' gridTemplateColumns={['1fr 1fr']} gap='12'>
            <Box h='full' overflowY='scroll' p='1'>
                <Heading mb='3'>Add Resource</Heading>
                <ResourceForm
                    courseId={params.courseId}
                    courseResources={course.resources}
                    parsedData={resourceParsedData as ExtraCourseData}
                    onSubmit={handleSubmit}
                    resourceClassToResourceTypes={course.schema}
                />
            </Box>
            <VStack
                h='full'
                overflowY={'scroll'}
                borderRadius={'md'}
                p='6'
                bg={ingestionTaskQueueBg}
                gap='6'
                alignItems={'stretch'}
            >
                {
                    ingestionTasks.map((formResult, i) => <IngestionTask courseId={params.courseId} formResult={formResult} key={i} />)
                }
            </VStack>
        </Grid>
    )
}