// All code that interacts directly with Cloud Storage should be found here.
// If this abstraction is maintained, switching to a different storage provider, ex., S3, would be painless

import { ref, getDownloadURL, uploadBytesResumable, deleteObject, listAll } from 'firebase/storage'
import { storage } from './firebase'

export async function getURLForPage(path: string) {
    const storageRef = ref(storage, path)

    try {
        let url = await getDownloadURL(storageRef)
        return url
    } catch (e) {
        console.error(`Couldn't find ${path} in cloud storage:`, e)
        return undefined
    }
}

export async function listSubpaths(path: string) {
    const storageRef = ref(storage, path)

    try {
        const result = await listAll(storageRef)
        const files = result.items.map((itemRef) => itemRef.fullPath)
        const prefixes = result.prefixes.map((prefixRef) => prefixRef.fullPath)

        return { files, prefixes } // Return files and directories
    } catch (error) {
        console.error("Error listing subpaths:", error)
        throw error
    }
}

export async function listSubpathsOfSubDirs(path: string, subdirs: string[]) {
    // Create an array of promises for listAll calls
    const listAllPromises = subdirs.map(async (subdir) => {
        const pathRef = ref(storage, `${path}/${subdir}`)
        try {
            const result = await listAll(pathRef)

            // Collect files and directories for this subdir
            return {
                files: result.items.map((itemRef) => itemRef.fullPath),
                prefixes: result.prefixes.map((prefixRef) => prefixRef.fullPath),
            }
        } catch (error) {
            console.warn(`Skipping ${subdir}:`, error.message)
            // Return empty arrays if the subdir doesn't exist or fails
            return { files: [], prefixes: [] }
        }
    })

    // Wait for all listAll promises to complete
    const results = await Promise.all(listAllPromises)

    // Combine all files and prefixes
    const allFiles = results.flatMap((result) => result.files)
    const allPrefixes = results.flatMap((result) => result.prefixes)

    return { files: allFiles, prefixes: allPrefixes }
}

export function uploadFile(path: string, file, updateProgress: (p: number) => void) {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage, path)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred * 100) / snapshot.totalBytes)
                updateProgress(progress)
            },
            error => {
                reject(error)
            },
            () => {
                resolve(uploadTask.snapshot)
            }
        )
    })
}

export async function deleteFile(path: string) {
    const fileRef = ref(storage, path)

    try {
        await deleteObject(fileRef)
        console.log(`File deleted successfully: ${path}`)
    } catch (error) {
        console.error("Error deleting file:", error)
        throw error
    }
}