import { useReducer } from "react";

type Action<T> =
    | { type: 'CLEAR' }
    | { type: 'UPDATE_KEY'; key: string; item: T }
    | { type: 'SET_STATE'; updater: (prevState: { [key: string]: T }) => { [key: string]: T } };

function reducer<T>(state: { [key: string]: T }, action: Action<T>) {
    switch (action.type) {
        case 'CLEAR':
            return {}
        case 'UPDATE_KEY':
            return {
                ...state,
                [action.key]: action.item
            }
        case 'SET_STATE':
            return action.updater(state)
        default:
            return state
    }
}

export default function useMap<T>(initialMap = {}) {
    const [state, dispatch] = useReducer(reducer<T>, initialMap)

    const clear = () => dispatch({ type: 'CLEAR' })
    const updateKey = (key: string, item: T) => dispatch({ type: 'UPDATE_KEY', key, item })
    const setState = (updater: (prevState: { [key: string]: T }) => { [key: string]: T }) =>
        dispatch({ type: 'SET_STATE', updater })

    return [state, clear, updateKey, setState] as [
        { [key: string]: T },
        () => void,
        (key: string, item: T) => void,
        (updater: (prevState: { [key: string]: T }) => { [key: string]: T }) => void
    ]
}
