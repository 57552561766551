import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../lib/firebase";
import { Course, ExtraCourseData } from "../types/types";

export type UserAdminLevels = {
    [key: string]: number
}

export default function useSingleCourse(courseId: string, includeParsedData = false, includeAdminLevels = false) {
    const [course, setCourse] = useState(undefined)
    const [isMainDataLoading, setIsMainDataLoading] = useState(true)
    const [isParsedDataLoading, setIsParsedDataLoading] = useState(true)
    const [isAdminLevelsLoading, setIsAdminLevelsLoading] = useState(true)

    const [parsedData, setParsedData] = useState(undefined)
    const [adminLevels, setAdminLevels] = useState(undefined)

    const fetchParsedData = async () => {
        const docRef = doc(db, "Courses", courseId, 'Data', 'parsed')

        const unsub = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                setParsedData(doc.data())
            } else {
                console.error(`Parsed data document Courses/${courseId}/Data/parsed does not exist`)
            }
            setIsParsedDataLoading(false)
        })
    }

    const fetchAdminLevels = async () => {
        const docRef = doc(db, "Courses", courseId, 'Data', 'users')

        const unsub = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                setAdminLevels(doc.data().userAdminLevels)
            } else {
                console.error(`Parsed data document Courses/${courseId}/Data/users does not exist`)
            }
            setIsAdminLevelsLoading(false)
        })
    }

    const fetchCourse = async () => {

        const docRef = doc(db, "Courses", courseId)

        const unsub = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                setCourse({ ...doc.data(), id: courseId })
            } else {
                console.error(`Course document Courses/${courseId} does not exist`)
            }
            setIsMainDataLoading(false)
        })
    }

    useEffect(() => {
        fetchCourse()

        if (includeParsedData) fetchParsedData()
        else setIsParsedDataLoading(false)

        if (includeAdminLevels) fetchAdminLevels()
        else setIsAdminLevelsLoading(false)
    }, [courseId])


    if (!includeParsedData && !includeAdminLevels) {
        return [course, isParsedDataLoading || isMainDataLoading || isAdminLevelsLoading] as [Course, boolean]
    }

    if (!includeParsedData && includeAdminLevels) {
        return [course, isParsedDataLoading || isMainDataLoading || isAdminLevelsLoading, adminLevels] as [Course, boolean, UserAdminLevels]
    }

    if (includeParsedData && !includeAdminLevels) {
        return [course, isParsedDataLoading || isMainDataLoading || isAdminLevelsLoading, parsedData] as [Course, boolean, ExtraCourseData]
    }

    if (includeParsedData && includeAdminLevels) {
        return [course, isParsedDataLoading || isMainDataLoading || isAdminLevelsLoading, parsedData, adminLevels] as [Course, boolean, ExtraCourseData, UserAdminLevels]
    }
}