import { arrayRemove, arrayUnion, collection, doc, documentId, getDoc, getDocs, increment, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import { db } from "./firebase";
import { Annotator, Conversation, Exchange, ResponseLevelAnnotation, UserCourseDoc } from "../types/types";
import _ from "lodash";
import { FilterDecision, FILTER_DECISIONS } from "../routes/admin/usage/types";


export type Feedback = {
    pairIndex: number
    valence: 'positive' | 'negative'
    qualitative?: string
}

export function makeCourseId(institution: string, courseCode: string, year: number, semester: string) {
    return `${institution.toLowerCase().replaceAll(" ", "-")}-${courseCode.toLowerCase().replaceAll(" ", "-")}-${semester.toLowerCase()}-${year}`
}

export async function fetchUserCourses(courseIds) {
    const coursesCollection = collection(db, "Courses")
    const q = query(coursesCollection, where(documentId(), "in", courseIds))
    const snapshots = await getDocs(q)
    return snapshots.docs.map((s, i) => ({ ...s.data(), id: s.id }))
}

export async function fetchUserDoc(uid: string) {
    const docRef = doc(db, "Users", uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        // docSnap.data() will be undefined in this case
        console.error(`Error during fetch of conversation. User doc does not exist!`);
        return undefined
    }
}

export async function getConversationDoc(courseId: string, convoId: string) {
    const docRef = doc(db, "Courses", courseId, "Conversations", convoId)
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        // docSnap.data() will be undefined in this case
        console.error(`Error during fetch of conversation. Document ${convoId} in ${courseId} does not exist!`);
        return undefined
    }
}

export async function setHasAcceptedTermsToTrue(uid: string) {
    const docRef = doc(db, "Users", uid)

    updateDoc(docRef, {
        hasAcceptedTermsOfService: true
    })
        .catch((err) => console.error(`Error during setHasAcceptedTermsToTrue. Error: ${err}`))
}

export async function addFeedbackToConversationDoc(courseId: string, conversationDocId: string, feedback) {
    const docRef = doc(db, "Courses", courseId, "Conversations", conversationDocId)

    await updateDoc(docRef, {
        hasFeedback: true,
        feedback: arrayUnion(feedback),
    })
}

export function onArchiveConversation(conversationDocId: string, courseId: string, uid: string) {
    const convoDocRef = doc(db, "Courses", courseId, "Conversations", conversationDocId)

    updateDoc(convoDocRef, {
        isArchived: true
    })

    const userCourseDocRef = doc(db, "Users", uid, "UserCourseData", courseId)
    updateDoc(userCourseDocRef, {
        [`conversations.${conversationDocId}.isArchived`]: true
    })
}

export function toggleUserBoot(uid: string, courseId: string, value: boolean) {
    const docRef = doc(db, "Users", uid)

    const change = value ? arrayUnion(courseId) : arrayRemove(courseId)

    updateDoc(docRef, {
        bootedCourses: change
    })
}

export function updateCourseControl(courseId: string, control: string, value: any) {
    const docRef = doc(db, "Courses", courseId)

    updateDoc(docRef, {
        [`controls.${control}`]: value
    })
}

export function addCourseToUser(uid: string, courseId: string, courseQueriesQuota: number, adminLevel: number = 0) {
    const userDocRef = doc(db, "Users", uid)

    const update: any = {
        "courses": arrayUnion(courseId),
        [`adminLevels.${courseId}`]: adminLevel
    }

    updateDoc(userDocRef, update)

    addBlankUserCourseDocToUser(uid, courseId, courseQueriesQuota)
}

export function addBlankUserCourseDocToUser(uid: string, courseId: string, courseQueriesQuota: number) {
    const userCourseDocRef = doc(db, "Users", uid, "UserCourseData", courseId)

    const blankUserCourseDoc: UserCourseDoc = {
        days: {},
        totalExchanges: 0,
        totalConversations: 0,
        // actionCounts: Object.fromEntries(validActionDecisions.map((action, i) => [action, 0])) as { [key in ActionDecision]: number },
        filterDecisionCounts: Object.fromEntries(FILTER_DECISIONS.map((filter, i) => [filter, 0])) as { [key in FilterDecision]: number },
        conversations: {},
        queriesLeft: courseQueriesQuota,
        timestampLastQueried: Timestamp.now(),
        timestampLastRegenerated: Timestamp.now()
    }

    setDoc(userCourseDocRef, blankUserCourseDoc)
}

export async function addEmailsToUserList(courseId: string, emails: string[]) {
    const docRef = doc(db, "Courses", courseId, "Data", "users")

    const usersDoc = await getDoc(docRef)

    const data = usersDoc.data()

    for (const email of emails) {
        data.userAdminLevels[email] = 0
    }

    await updateDoc(docRef, data)
}

export async function fetchAllConversationsWithFeedback(courseId: string) {
    const q = query(collection(db, 'Courses', courseId, 'Conversations'), where('hasFeedback', '==', true))

    const snapshot = await getDocs(q)
    return snapshot.docs.map(d => ({ ...d.data(), id: d.id })) as Conversation[]
}

export function updateConversationAnnotations(courseId: string, conversationDocId: string, responseAnnotations: ResponseLevelAnnotation[], annotator: Annotator, previousAnnotations: Conversation["annotations"]) {
    const docRef = doc(db, "Courses", courseId, "Conversations", conversationDocId)

    const updatedAnnotation = {
        responseLevelAnnotations: responseAnnotations,
        // conversationLevelAnnotation: conversationAnnotation,
        annotator: annotator,
        timestampLastEdited: Timestamp.now()
    }

    let annotations: Conversation["annotations"] = [...previousAnnotations]
    if (previousAnnotations.length == 0) {
        annotations.push(updatedAnnotation)
    } else {
        // find entry in previousAnnotations with annotator
        const annotatorIndex = previousAnnotations.findIndex(({ annotator: ann }) => annotator.uid == ann.uid)
        if (annotatorIndex == -1) {
            // new annotator
            annotations.push(updatedAnnotation)
        } else {
            // don't updateDoc if annotations are the same (avoids infinite loop)
            if (_.isEqual(updatedAnnotation.responseLevelAnnotations, previousAnnotations[annotatorIndex].responseLevelAnnotations)) {
                console.log("updated annotations are same as previous annotations --> not saving to doc")
                return
            }

            annotations[annotatorIndex] = updatedAnnotation
        }
    }


    updateDoc(docRef, {
        "annotations": annotations,
        "numAnnotations": annotations.length
    })
}

export function incrementNotificationsSeen(uid: string) {
    const userDocRef = doc(db, "Users", uid)

    updateDoc(userDocRef, {
        notificationsSeen: increment(1)
    })
}