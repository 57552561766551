import { Avatar, Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Hide, IconButton, Img, Input, MenuIcon, Show, Text, useColorMode, useColorModeValue, VStack } from "@chakra-ui/react";
import Logo from "./Logo";
import { Link, useLocation, useMatch, useParams } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../lib/firebase";
import { capitalizeWords } from "../lib/text_utils";
import { useAuthState } from "react-firebase-hooks/auth";
import useUserDoc from "../hooks/useUserDoc";
import { HamburgerIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import { ADMIN_LEVEL_TO_COURSE_NAV_ITEMS, NavItemData, SidebarItem } from "../routes/course";
import { useState } from "react";
import { BiChalkboard, BiLogOut } from "react-icons/bi";



const GLOBAL_NAV_ITEMS: NavItemData[] = [
    {
        title: 'Courses',
        to: '/app/dashboard',
        dividerItem: false,
        SideIcon: BiChalkboard,
    },
    // {
    //     title: 'Settings',
    //     route: '/app/settings'
    // },

    {
        title: "Log out",
        to: undefined,
        dividerItem: false,
        SideIcon: BiLogOut,
        onClick: () => signOut(auth)
    }
]


export default function AppNav({ photoURL }) {

    const params = useParams()
    const prettyCourseName = params?.courseId ? params.courseId.replaceAll("-", " ") : false
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

    const [user, userLoading, userError] = useAuthState(auth)
    const [userDoc, isUserDoc] = useUserDoc(user)

    const navItems = [...GLOBAL_NAV_ITEMS]

    // const brownLogoSrc = useColorModeValue('/brown_logo.png', '/brown_logo_white_text.png')

    // add COURES_NAV_ITEMS on paths that match /app/:courseId
    if (params.courseId !== undefined && isUserDoc) {
        navItems.push({ dividerItem: true, SideIcon: undefined, title: undefined, to: undefined })

        for (let i = 0; i <= userDoc.adminLevels[params.courseId]; i++) {
            for (const navItem of ADMIN_LEVEL_TO_COURSE_NAV_ITEMS[i]) {
                navItems.push({ ...navItem, to: `/app/${params.courseId}/${navItem.to}` })
            }
        }
    }

    return (
        <Box bg={useColorModeValue('slate.800', 'slate.900')} flexShrink={0} px='3'>
            <Flex py='3' margin={'auto'} maxW={'6xl'} alignItems={'center'} justifyContent={'space-between'}>
                <Flex alignItems={'center'} gap='6'>
                    <Link to="/">
                        <Logo isSmall />
                    </Link>
                    {/* { prettyCourseName && <Text color={} fontSize='sm'>{prettyCourseName}</Text>} */}
                </Flex>

                <Flex alignItems={'center'} gap={3}>
                    <Hide below="lg">
                        {GLOBAL_NAV_ITEMS.map((item, i) => <NavItem {...item} key={i} />)}
                    </Hide>

                    <ColorModeToggle />

                    <Img
                        src={'/brown_logo_white_text.png'}
                        w='10'
                        h='auto'
                        p='1'
                    />

                    {/* <Avatar ml='2' size='sm' src={photoURL}/> */}

                    <Show below="lg">
                        <IconButton
                            aria-label="open menu"
                            icon={<HamburgerIcon />}
                            color='white'
                            bg={useColorModeValue('slate.800', 'slate.900')}
                            onClick={() => setMobileDrawerOpen(true)}
                        />
                        <MobileDrawer
                            isOpen={mobileDrawerOpen}
                            onClose={() => setMobileDrawerOpen(false)}
                            items={navItems}
                        />
                    </Show>
                </Flex>

            </Flex>
        </Box>
    )
}

function MobileDrawer({ isOpen, onClose, items }) {
    const dividerColor = useColorModeValue('slate.300', 'slate.600')

    return (
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
        >
            <DrawerOverlay />
            <DrawerContent
                bg={useColorModeValue('slate.100', 'slate.800')}
            >
                <DrawerCloseButton />
                <DrawerHeader>
                    <Logo darkOnLight />
                </DrawerHeader>

                <DrawerBody>
                    <VStack alignItems={'stretch'}>
                        {items.map((item, i) => item.dividerItem ? <Divider key={i} borderColor={dividerColor} /> : <SidebarItem {...item} key={i} />)}
                    </VStack>
                </DrawerBody>

                <DrawerFooter>
                    {/* <Button variant='outline' mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme='blue'>Save</Button> */}
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export function ColorModeToggle({ }) {
    const { colorMode, toggleColorMode } = useColorMode()

    return (
        <IconButton
            icon={colorMode == 'dark' ? <MoonIcon /> : <SunIcon />}
            aria-label="toggle color mode"
            color={'white'}
            // bg={useColorModeValue('primary.700', 'primary.800')}
            onClick={toggleColorMode}
            _hover={{ bg: useColorModeValue('slate.700', 'slate.700') }}
            variant={'ghost'}
        />
    )
}

export function NavItem({ title, to, onClick = undefined, small = false }) {
    const hoverBg = useColorModeValue('slate.700', 'slate.700')

    return (
        <Button
            as={Link}
            to={to}
            colorScheme={'slate'}
            variant='ghost'
            fontWeight={'400'}
            color={'white'}
            // bg={match ? hoverBg : 'slate.200'}
            _hover={{ bg: hoverBg }}
            onClick={onClick}
            size={small ? 'sm' : 'md'}
        >
            {title}
        </Button>
    )
}