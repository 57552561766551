import { Button, ButtonGroup, Card, CardBody, Center, Text, GridItem, HStack, Select, SimpleGrid, Spinner, Stat, StatLabel, StatNumber, useColorModeValue, Heading } from "@chakra-ui/react";
import { formatDateLong, formatDateShort, formatDateShortNumerical, formatHour, getDaysInBetween, getEndOfWeek, getLastXDays, getToday, minDate } from "../../../lib/date_utils";
import Chart from "../Chart";
import useDaySummaries, { DaySummaryPlus } from "../../../hooks/useDaySummaries";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { getStartAndEndOfCourse } from "./logic";
import useSingleCourse from "../../../hooks/useSingleCourse";
import { Course } from "../../../types/types";
import { FILTER_DECISIONS, HourSummary, ResourceCount } from "./types";


type DateRange = 'Day' | 'Week' | 'Month' | 'Semester'

const DATE_RANGES: DateRange[] = ['Day', 'Week', 'Month', 'Semester'];

const DATE_RANGE_TO_DAYS = {
    'Day': 1,
    'Week': 7,
    'Month': 30,
    'Semester': 200
}

export default function Usage() {
    const params = useParams()

    const [course, isCourseLoading] = useSingleCourse(params.courseId)

    if (isCourseLoading) return <Spinner />

    return <UsageCore course={course} />
}

function UsageCore({ course }: { course: Course }) {
    const [startOfSemester, endOfSemester] = getStartAndEndOfCourse(course)

    const [focusDay, setFocusDay] = useState(formatDateShortNumerical(minDate(getToday(), endOfSemester)))
    const daysSinceSemesterStart = getDaysInBetween(focusDay, startOfSemester)
    const dates = getLastXDays(getDaysInBetween(getToday(), startOfSemester) - 1)
    const allDays = getLastXDays(daysSinceSemesterStart, focusDay).map(formatDateShortNumerical)
    const datesDescending = [...dates].reverse()

    const queryDates = getLastXDays(daysSinceSemesterStart + 14, focusDay).map(formatDateShortNumerical)
    const [daySummaries, isLoadingSummaries] = useDaySummaries(course.id, queryDates)

    // const [conversations, totalConversationCount, isLoadingConversations, fetchMoreConvos] = useCourseConversations(params.courseId, 10000)

    const [selectedRange, setSelectedRange] = useState<DateRange>('Day');

    const numberOfDays = Math.min(DATE_RANGE_TO_DAYS[selectedRange], daysSinceSemesterStart - 1)
    const daysInRange = queryDates.slice(queryDates.length - numberOfDays)

    return (
        <SimpleGrid
            gap='8'
            gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']}
            w='full'
        >

            <GridItem colSpan={[1, null, null, null, 2]}>
                <HStack w='full'>
                    <ButtonGroup isAttached w='full'>
                        {DATE_RANGES.map((range, i) => (
                            <Button
                                flex='1'
                                key={i}
                                // color={'slate.700'}
                                variant={range == selectedRange ? 'solid' : 'outline'}
                                colorScheme={'slate'}
                                fontWeight={'400'}
                                onClick={() => setSelectedRange(range)}
                            >
                                {range}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <Text style={{ whiteSpace: 'nowrap' }}>up to</Text>
                    <Select value={focusDay} bg={useColorModeValue('white', 'slate.900')} maxW='xs' onChange={(e) => setFocusDay(e.target.value)}>
                        {datesDescending.map((d, i) => <option value={formatDateShortNumerical(d)} key={i}>
                            {formatDateLong(getToday()) == formatDateLong(d) ? formatDateLong(d) + ' (Today)' : formatDateLong(d)}
                        </option>)}
                    </Select>
                </HStack>
            </GridItem>

            {isLoadingSummaries ?
                <GridItem colSpan={[1, null, null, null, 2]}>
                    <Center py="32" flexDirection="column">
                        <Spinner />
                    </Center>
                </GridItem> :
                <Charts
                    daySummaries={daySummaries}
                    daysInRange={daysInRange}
                    allDays={allDays}
                    resources={course.resources}
                // conversations={conversations}
                />}

        </SimpleGrid>
    )
}

function Charts({ daySummaries, daysInRange, allDays, resources }: { daySummaries: { [key: string]: DaySummaryPlus }, daysInRange: string[], allDays: string[], resources: Course["resources"] }) {

    const lightTextColor = useColorModeValue('slate.400', 'slate.500')

    const dayScale = daysInRange.length > 1

    // const hourlyData = daysInRange.map((d) =>
    //     daySummaries[d] ? daySummaries[d].hourSummaries : []
    // ).flat()

    let lastDayHourlyData: HourSummary[]
    const lastDay = daySummaries[formatDateShortNumerical(daysInRange[daysInRange.length - 1])]
    if (lastDay !== undefined && lastDay.exists) {
        lastDayHourlyData = daySummaries[formatDateShortNumerical(daysInRange[daysInRange.length - 1])]?.hourSummaries
    } else {
        lastDayHourlyData = []
    }

    // const conversationsWithFeedback = conversations.filter((c) => c.feedback)

    // const activeUsersPerHour = hourlyData.map(({ numActiveUsers }, i) => numActiveUsers)
    // const numExchangesPerHour = hourlyData.map(({ numExchanges }) => numExchanges)
    // const numConversationsStartedPerHour = hourlyData.map(({ numConversationsStarted }) => numConversationsStarted)
    // const hourLabels = hourlyData.map(({ timestamp }, i) => formatHour(timestamp))

    // new user
    const allNewUsersPer = allDays.map((day, index) => {
        const summary = daySummaries[day]
        if (summary !== undefined && summary.exists) {
            const activeUserIds = summary.activeUIDs
            const previousSummaries = allDays.slice(0, index)
                .map((d) => daySummaries[d])
            const previousUserIds = previousSummaries.flatMap((s) => s.activeUIDs)
            const newUsers = activeUserIds.filter(
                (userId) => !previousUserIds.includes(userId)
            )
            return newUsers.length
        } else {
            return 0
        }
    })
    const newUsersPer = daysInRange.map((day, index) => {
        const allDaysIndex = allDays.indexOf(day)
        return allNewUsersPer[allDaysIndex]
    })

    // adoption (cumulative sum of new users)
    // const allAdoption = allNewUsersPer.map((newUsers, i) => allNewUsersPer.slice(0, i + 1).reduce((acc, n) => acc + n, 0) / users.length * 100)
    // const adoption = daysInRange.map((day, index) => {
    //     const allDaysIndex = allDays.indexOf(day)
    //     return allAdoption[allDaysIndex]
    // });

    // user retention
    const retentionCounts = [7, 30];
    const userRetention = retentionCounts.map((count: number) => {
        if (!dayScale) // not defining retention for hourly data
            return [];
        let summaries
        let allSummaries
        if (!daySummaries[formatDateShortNumerical(daysInRange[0])].exists)
            return []
        summaries = daysInRange.map((d) => daySummaries[formatDateShortNumerical(d)])
        allSummaries = allDays.map((d) => daySummaries[formatDateShortNumerical(d)])

        return summaries.map((summary: DaySummaryPlus, i: number) => {
            if (summary.exists) {
                const previousSummariesStartIdx = Math.max(0, allSummaries.length - summaries.length - count + i)
                const previousSummariesEndIdx = allSummaries.length - summaries.length + i - 1
                const previousSummaries = allSummaries.slice(previousSummariesStartIdx, previousSummariesEndIdx)
                const previousUserIdsSet = new Set(previousSummaries.flatMap((s) => s.activeUIDs))
                const oldUsers = summary.activeUIDs.filter((userId) => previousUserIdsSet.has(userId))
                return oldUsers.length / previousUserIdsSet.size * 100
            } else {
                return 0
            }
        })
    }, [daysInRange, daySummaries, allDays, dayScale])

    // socratic to information ratio
    // const [socraticPercentages, infoPercentages] = useMemo(() => {
    //     let summaries
    //     if (dayScale) {
    //         summaries = daysInRange.map((d) => daySummaries[formatDateShortNumerical(d)])
    //     } else {
    //         if (!daySummaries[formatDateShortNumerical(daysInRange[0])].exists)
    //             return []
    //         summaries = daySummaries[formatDateShortNumerical(daysInRange[0])].hourSummaries.map((s) => ({ ...s, exists: true }))
    //     }

    //     const socraticPercentages = []
    //     const infoPercentages = []
    //     for (const summary of summaries) {
    //         if (summary.exists) {
    //             const socraticNum = summary.actionCounts["SOCRATIC"] + summary.actionCounts["DIAGNOSTIC"]
    //             const infoNum = summary.actionCounts["INFORMATION"]
    //             const total = socraticNum + infoNum + 1e-5
    //             socraticPercentages.push(Math.round((socraticNum / total) * 100))
    //             infoPercentages.push(Math.round((infoNum / total) * 100))
    //         } else {
    //             socraticPercentages.push(0)
    //             infoPercentages.push(0)
    //         }
    //     }
    //     return [socraticPercentages, infoPercentages]
    // }, [daysInRange, daySummaries, dayScale])

    // feedback
    // const allDaysFeedback: { [key: string]: Feedback[] } = allDays.reduce((acc, day) => {
    //     const feedbackForDay = conversationsWithFeedback
    //         .filter((c) => (c.timestampLastUpdated.toDate().toDateString() >= new Date(day).toDateString()) &&
    //             (new Date(day).toDateString() >= c.timestampCreated.toDate().toDateString()))
    //         .flatMap((c) => c.feedback);
    //     acc[day] = feedbackForDay;
    //     return acc;
    // }, {} as { [key: string]: Feedback[] });

    // const feedbackPercentages = daysInRange.map((d) => {
    //     const feedback = allDaysFeedback[d]
    //     if (!feedback) {
    //         return 0
    //     }
    //     const helpfulness = feedback.map((f) => f.helpfulness)
    //     return helpfulness.reduce((acc, n) => acc + n, 0) / helpfulness.length / 70 * 100
    // })

    // issues
    // const totalIssueCounts = daysInRange.map((d) => {
    //     const feedback = allDaysFeedback[d]
    //     if (!feedback) {
    //         return 0
    //     }
    //     return feedback.reduce((acc, f) => f.issues.length > 0 ? acc + f.issues.length : acc + 1, 0)
    // })

    // const allIssuesSet = new Set<string>();
    // allDays.forEach((day) => {
    //     allDaysFeedback[day].forEach((feedback) => {
    //         feedback.issues.forEach((issue) => allIssuesSet.add(issue));
    //     });
    // })
    // const allUniqueIssues = Array.from(allIssuesSet);
    // const allUniqueIssuesAndNone = [...allUniqueIssues, 'None'];

    // const issueCounts: { [issue: string]: number[] } = {};
    // const issuePercentages: { [issue: string]: number[] } = {};
    // const noIssueCounts = daysInRange.map((day, index) => {
    //     const feedbackForDay = allDaysFeedback[day];
    //     if (feedbackForDay.length === 0)
    //         return 0;

    //     const noIssueCount = feedbackForDay.reduce((count, feedback) => {
    //         return count + (feedback.issues.length == 0 ? 1 : 0);
    //     }, 0);
    //     return noIssueCount
    // })
    // const noIssuesPercentages = noIssueCounts.map((count, index) => {
    //     const issueCounts = totalIssueCounts[index];
    //     if (issueCounts === 0)
    //         return 0

    //     return count / issueCounts * 100;
    // })

    // allUniqueIssues.forEach((issue) => {
    //     issueCounts[issue] = daysInRange.map((day, index) => {
    //         const feedbackForDay = allDaysFeedback[day];
    //         if (feedbackForDay.length === 0)
    //             return 0;

    //         const issueCount = feedbackForDay.reduce((count, feedback) => {
    //             return count + (feedback.issues.includes(issue) ? 1 : 0);
    //         }, 0);

    //         return issueCount;
    //     });
    //     issuePercentages[issue] = issueCounts[issue].map((count, index) => {
    //         const issueCounts = totalIssueCounts[index];
    //         if (issueCounts === 0)
    //             return 0;

    //         return count / issueCounts * 100;
    //     })
    // })
    // const issueCountsAndNone = [...Object.values(issueCounts), noIssueCounts];
    // const issuePercentagesAndNone = [...Object.values(issuePercentages), noIssuesPercentages];

    // resources
    // const allDaysResources: { [key: string]: string[] } = allDays.reduce((acc, day) => {
    //     const topicsForDay = conversations
    //         .filter((c) => (c.timestampLastUpdated.toDate().toDateString() == new Date(day).toDateString()))
    //         .flatMap((c) => Array(c.numResponses).fill(c.resourceName))
    //     acc[day] = topicsForDay
    //     return acc
    // }, {} as { [key: string]: string[] })

    // const allResourcesSet = new Set<string>()
    // allDays.forEach((day) => {
    //     allDaysResources[day].forEach((resource) => allResourcesSet.add(resource))
    // })
    // const allUniqueResources = Array.from(allResourcesSet)

    // const resourceProportions: { [resource: string]: number[] } = {}
    // allUniqueResources.forEach((resource) => {
    //     resourceProportions[resource] = daysInRange.map((day) => {
    //         const resourcesForDay = allDaysResources[day]
    //         if (resourcesForDay.length === 0)
    //             return 0

    //         const resourceCount = resourcesForDay.reduce((count, thisResource) => {
    //             return count + (resource === thisResource ? 1 : 0)
    //         }, 0)

    //         return resourceCount / resourcesForDay.length * (1 - 1e-5) * 100
    //     })
    // })

    // terminations
    // const allDaysTerminations: { [key: string]: number } = daysInRange.reduce((acc, day) => {
    //     const conversationsForDay = conversations.filter((c) => (c.timestampLastUpdated.toDate().toDateString() == new Date(day).toDateString()))
    //     const terminationsForDay = conversationsForDay.filter((c) => c.isTerminated)
    //     acc[day] = terminationsForDay.length / conversationsForDay.length * 100
    //     return acc
    // }, {} as { [key: string]: number })

    // tutor engine versions
    // const allTutorEngineVersionsSet = new Set<string>();
    // allDays.forEach((day) => {
    //     conversationsWithFeedback.filter((c) => (c.timestampLastUpdated.toDate().toDateString() == new Date(day).toDateString()))
    //         .forEach((c) => allTutorEngineVersionsSet.add(c.lastTutorEngineVersion))
    // })
    // const allUniqueTutorEngineVersions = Array.from(allTutorEngineVersionsSet)
    // const tutorEngineVersionsFeedback = allUniqueTutorEngineVersions.map((version) => {
    //     const versionFeedback = conversationsWithFeedback.filter((c) => c.lastTutorEngineVersion === version).flatMap((c) => c.feedback)
    //     const helpfulness = versionFeedback.map((f) => f.helpfulness)
    //     return helpfulness.reduce((acc, n) => acc + n, 0) / helpfulness.length / 70 * 100
    // })

    // filter decisions, actions
    // const accumulateFilterDecisionCounts = () => {
    //     const filterDecisionCounts = Object.fromEntries(FILTER_DECISIONS.map((d) => [d, 0]))
    //     for (const day of daysInRange) {
    //         const summary = daySummaries[formatDateShortNumerical(day)]
    //         if (summary.exists) {
    //             for (const decision of FILTER_DECISIONS) filterDecisionCounts[decision] += summary.filterDecisionCounts[decision]
    //         }
    //     }
    //     return filterDecisionCounts
    // }

    // const accumulateActionCounts = () => {
    //     const actionCounts = Object.fromEntries(ACTION_DECISIONS.map((d) => [d, 0]))
    //     for (const day of daysInRange) {
    //         const summary = daySummaries[formatDateShortNumerical(day)]
    //         if (summary.exists) {
    //             for (const decision of ACTION_DECISIONS) actionCounts[decision] += summary.actionCounts[decision]
    //         }
    //     }
    //     return actionCounts
    // }

    // meta
    const extractKeyOrZero = (d: string, key: string, defaultValue: any = 0) => {
        const summary = daySummaries[formatDateShortNumerical(d)]
        if (summary.exists) {
            return summary[key]
        } else {
            return defaultValue
        }
    }

    const labels = dayScale ? daysInRange.map(formatDateShort) : lastDayHourlyData.map(({ timestamp }) => formatHour(timestamp.toDate()))

    const conversationsStartedPer = dayScale ? daysInRange.map((d) => extractKeyOrZero(d, "numConversationsStarted")) : lastDayHourlyData.map(({ numConversationsStarted }) => numConversationsStarted)
    const exchangesPer = dayScale ? daysInRange.map((d) => extractKeyOrZero(d, "numExchanges")) : lastDayHourlyData.map(({ numExchanges }) => numExchanges)
    const activeUsersPer = dayScale ? daysInRange.map((d) => extractKeyOrZero(d, "numActiveUsers")) : lastDayHourlyData.map(({ numActiveUsers }) => numActiveUsers)

    const averageConversationLengths = dayScale ? daysInRange.map((d) => extractKeyOrZero(d, "averageConversationLength")) : lastDayHourlyData.map(({ averageConversationLength }) => averageConversationLength)
    const averageExchangeCosts = dayScale ? daysInRange.map((d) => extractKeyOrZero(d, "averageExchangeCost")) : lastDayHourlyData.map(({ averageExchangeCost }) => averageExchangeCost)
    const averageLatencies = daysInRange.map((d) => extractKeyOrZero(d, "averageLatency"))
    const numConversations = dayScale ? daysInRange.map((d) => extractKeyOrZero(d, "numTotalConversations")) : lastDayHourlyData.map(({ numTotalConversations }) => numTotalConversations)

    const dateRangeString = daysInRange[0] != daysInRange[daysInRange.length - 1] ? ' to ' + formatDateLong(daysInRange[daysInRange.length - 1]) : ''

    // compute query counts per resource per day
    const resourceCountsPer = dayScale ? daysInRange.map(d => extractKeyOrZero(d, "resourceCounts", [])) : lastDayHourlyData.map(({ resourceCounts }) => resourceCounts)
    const uniqueResources: string[] = []
    for (const [resourceClass, resourceNames] of Object.entries(resources)) {
        for (const resourceName of Object.keys(resourceNames)) {
            uniqueResources.push(`${resourceClass}/${resourceName}`)
        }
    }


    const resourceToQueriesPer: { [key: string]: number[] } = Object.fromEntries(uniqueResources.map(r => [r, []]))
    resourceCountsPer.forEach((rcs: ResourceCount[]) => {
        const resourcesForDay = [...uniqueResources]
        for (const resourceCount of rcs) {
            const key = `${resourceCount.resourceClass}/${resourceCount.resourceName}`
            resourceToQueriesPer[key].push(resourceCount.queryCount)
            resourcesForDay.splice(resourcesForDay.indexOf(key), 1)
        }
        for (const resource of resourcesForDay) {
            resourceToQueriesPer[resource].push(0)
        }
    })

    // compute query counts per filter decision per day
    const filterDecisionCountsPer = dayScale ? daysInRange.map(d => extractKeyOrZero(d, "filterDecisionCounts", {})) : lastDayHourlyData.map(({ filterDecisionCounts }) => filterDecisionCounts)
    const filterDecisionToQueriesPer: { [key: string]: number[] } = Object.fromEntries(FILTER_DECISIONS.map(d => [d, []]))
    filterDecisionCountsPer.forEach((fds: DaySummaryPlus["filterDecisionCounts"]) => {
        const filterDecisionsForDay = [...FILTER_DECISIONS]
        for (const [filterDecision, count] of Object.entries(fds)) {
            console.log(count)
            filterDecisionToQueriesPer[filterDecision].push(count)
            filterDecisionsForDay.splice(filterDecisionsForDay.indexOf(filterDecision))
        }
        for (const filterDecision of filterDecisionsForDay) {
            filterDecisionToQueriesPer[filterDecision].push(0)
        }
    })

    return (
        <>
            {/* <Numbers
                averageConversationLength={avg(averageConversationLengths, numConversations) ?? 'N/A'}
                averageExchangeCost={avg(averageExchangeCosts, numConversations) ?? 'N/A'}
                averageLatency={avg(averageLatencies, numConversations) ?? 'N/A'}
                conversationsCreated={numConversations.reduce((acc, n) => acc + n, 0) ?? 0}
                daysInRange={daysInRange}
            /> */}

            <Card variant='outline'>
                <CardBody p={['2', null, '5']}>
                    <Heading size='md' textAlign={'center'}>Active Users</Heading>
                    <Text size='md' textAlign={'center'} color={lightTextColor}>
                        {formatDateLong(daysInRange[0])}
                        {dateRangeString}
                    </Text>
                    <Chart
                        data={[activeUsersPer]}
                        labels={labels}
                        xLabel={dayScale ? 'Day' : 'Hour'}
                        title={'Users'}
                        datasetLabels={['Users']}
                        yLabel={'Users'}
                        type={'line'}
                        suggestedYMax={10}
                        fill
                    />
                </CardBody>
            </Card>

            <Card variant='outline'>
                <CardBody p={['2', null, '5']}>
                    <Heading size='md' textAlign={'center'}>Conversations Started</Heading>
                    <Text size='md' textAlign={'center'} color={lightTextColor}>
                        {formatDateLong(daysInRange[0])}
                        {dateRangeString}
                    </Text>
                    <Chart
                        data={[conversationsStartedPer]}
                        labels={labels}
                        xLabel={dayScale ? 'Day' : 'Hour'}
                        title={'Conversations'}
                        datasetLabels={['Conversations']}
                        yLabel={'Conversations'}
                        type={'line'}
                        suggestedYMax={10}
                        fill
                    />
                </CardBody>
            </Card>

            <Card variant='outline'>
                <CardBody p={['2', null, '5']}>
                    <Heading size='md' textAlign={'center'}>Queries</Heading>
                    <Text size='md' textAlign={'center'} color={lightTextColor}>
                        {formatDateLong(daysInRange[0])}
                        {dateRangeString}
                    </Text>
                    <Chart
                        data={[exchangesPer]}
                        labels={labels}
                        xLabel={dayScale ? 'Day' : 'Hour'}
                        title={'Queries'}
                        datasetLabels={['Queries']}
                        yLabel={'Queries'}
                        type={'line'}
                        suggestedYMax={10}
                        fill
                    />
                </CardBody>
            </Card>

            <Card variant='outline'>
                <CardBody p={['2', null, '5']}>
                    <Heading size='md' textAlign={'center'}>Average Conversation Length</Heading>
                    <Text size='md' textAlign={'center'} color={lightTextColor}>
                        {formatDateLong(daysInRange[0])}
                        {dateRangeString}
                    </Text>
                    <Chart
                        data={[averageConversationLengths]}
                        labels={labels}
                        xLabel={dayScale ? 'Day' : 'Hour'}
                        title={'Average Conversation Length'}
                        datasetLabels={['Average Conversation Length']}
                        yLabel={'Queries'}
                        type={'line'}
                        suggestedYMax={10}
                        fill
                    />
                </CardBody>
            </Card>

            {/* <Card variant='outline'>
                <CardBody p={['2', null, '5']}>
                    <Heading size='md' textAlign={'center'}>Socratic to Information Ratio</Heading>
                    <Text size='md' textAlign={'center'} color={lightTextColor}>
                        {formatDateLong(daysInRange[0])}
                        {dateRangeString}
                    </Text>
                    <Chart
                        data={[socraticPercentages, infoPercentages]}
                        labels={labels}
                        xLabel={dayScale ? 'Day' : 'Hour'}
                        title={'Ratio'}
                        datasetLabels={['Socratic', 'Information']}
                        yLabel={'Ratio'}
                        type={'stackedbar'}
                        suggestedYMax={3}
                        fill
                    />
                </CardBody>
            </Card> */}

            <Card variant='outline'>
                <CardBody p={['2', null, '5']}>
                    <Heading size='md' textAlign={'center'}>Queries per Resource</Heading>
                    <Text size='md' textAlign={'center'} color={lightTextColor}>
                        {formatDateLong(daysInRange[0])}
                        {dateRangeString}
                    </Text>
                    <Chart
                        data={Object.values(resourceToQueriesPer)}
                        labels={labels}
                        xLabel={dayScale ? 'Day' : 'Hour'}
                        title={'Queries'}
                        datasetLabels={uniqueResources}
                        yLabel={'Queries'}
                        type={'stackedbar'}
                        hasBaseline
                        suggestedYMax={10}
                        fill
                    />
                </CardBody>
            </Card>

            <Card variant='outline'>
                <CardBody p={['2', null, '5']}>
                    <Heading size='md' textAlign={'center'}>Queries per Filter Decision</Heading>
                    <Text size='md' textAlign={'center'} color={lightTextColor}>
                        {formatDateLong(daysInRange[0])}
                        {dateRangeString}
                    </Text>
                    <Chart
                        data={Object.values(filterDecisionToQueriesPer)}
                        labels={labels}
                        xLabel={dayScale ? 'Day' : 'Hour'}
                        title={'Queries'}
                        datasetLabels={FILTER_DECISIONS}
                        yLabel={'Queries'}
                        type={'stackedbar'}
                        hasBaseline
                        suggestedYMax={10}
                        fill
                    />
                </CardBody>
            </Card>

            {dayScale ?
                <>
                    <Card variant='outline'>
                        <CardBody p={['2', null, '5']}>
                            <Heading size='md' textAlign={'center'}>First Time Users</Heading>
                            <Text size='md' textAlign={'center'} color={lightTextColor}>
                                {formatDateLong(daysInRange[0])}
                                {dateRangeString}
                            </Text>
                            <Chart
                                data={[newUsersPer]}
                                labels={labels}
                                datasetLabels={'New Users'}
                                xLabel={dayScale ? 'Day' : 'Hour'}
                                title={'New Users'}
                                yLabel={'New Users'}
                                type={'line'}
                                suggestedYMax={10}
                                fill
                            />
                        </CardBody>
                    </Card>

                    {/* <Card variant='outline'>
                        <CardBody p={['2', null, '5']}>
                            <Heading size='md' textAlign={'center'}>Adoption</Heading>
                            <Text size='md' textAlign={'center'} color={lightTextColor}>
                                {formatDateLong(daysInRange[0])}
                                {dateRangeString}
                            </Text>
                            <Chart
                                data={[adoption]}
                                labels={labels}
                                xLabel={dayScale ? 'Day' : 'Hour'}
                                title={'Adoption'}
                                datasetLabels={['Adoption']}
                                yLabel={'% Adoption'}
                                type={'line'}
                                suggestedYMax={100}
                                fill
                            />
                        </CardBody>
                    </Card> */}

                    {/* <Card variant='outline'>
                        <CardBody p={['2', null, '5']}>
                            <Heading size='md' textAlign={'center'}>Retention</Heading>
                            <Text size='md' textAlign={'center'} color={lightTextColor}>
                                {formatDateLong(daysInRange[0])}
                                {dateRangeString}
                            </Text>
                            <Chart
                                data={userRetention}
                                labels={labels}
                                xLabel={dayScale ? 'Day' : 'Hour'}
                                title={'Retention'}
                                datasetLabels={['7 Day', '30 Day']}
                                yLabel={'% Retention'}
                                type={'bar'}
                                suggestedYMax={1}
                                fill
                            />
                        </CardBody>
                    </Card> */}

                    {/* <Card variant='outline'>
                        <CardBody p={['2', null, '5']}>
                            <Heading size='md' textAlign={'center'}>Feedback</Heading>
                            <Text size='md' textAlign={'center'} color={lightTextColor}>
                                {formatDateLong(daysInRange[0])}
                                {dateRangeString}
                            </Text>
                            <Chart
                                data={[feedbackPercentages]}
                                labels={labels}
                                xLabel={dayScale ? 'Day' : 'Hour'}
                                title={'Feedback'}
                                datasetLabels={['Feedback']}
                                yLabel={'% Satisfaction'}
                                type={'line'}
                                suggestedYMax={100}
                                fill
                            />
                        </CardBody>
                    </Card> */}

                    {/* <Card variant={'outline'}>
                        <CardBody>
                            <Heading size='md' textAlign={'center'}>Issues</Heading>
                            <Text size='md' textAlign={'center'} color={lightTextColor}>
                                {formatDateLong(daysInRange[0])}
                                {dateRangeString}
                            </Text>
                            <Chart
                                data={Object.values(issueCountsAndNone)}
                                labels={labels}
                                xLabel={dayScale ? 'Day' : 'Hour'}
                                title={'Issues'}
                                datasetLabels={allUniqueIssuesAndNone}
                                yLabel={'Feedback'}
                                type={'stackedbar'}
                                suggestedYMax={1}
                                // displayLegend={true}
                                hasBaseline={true}
                                fill
                            />
                        </CardBody>
                    </Card> */}

                    {/* <Card variant={'outline'}>
                        <CardBody>
                            <Heading size='md' textAlign={'center'}>Issues (Normalized)</Heading>
                            <Text size='md' textAlign={'center'} color={lightTextColor}>
                                {formatDateLong(daysInRange[0])}
                                {dateRangeString}
                            </Text>
                            <Chart
                                data={Object.values(issuePercentagesAndNone)}
                                labels={labels}
                                xLabel={dayScale ? 'Day' : 'Hour'}
                                title={'Issues'}
                                datasetLabels={allUniqueIssuesAndNone}
                                yLabel={'% Feedback'}
                                type={'stackedbar'}
                                suggestedYMax={1}
                                // displayLegend={true}
                                hasBaseline={true}
                                fill
                            />
                        </CardBody>
                    </Card> */}

                    {/* <Card variant={'outline'}>
                        <CardBody>
                            <Heading size='md' textAlign={'center'}>Resources</Heading>
                            <Text size='md' textAlign={'center'} color={lightTextColor}>
                                {formatDateLong(daysInRange[0])}
                                {dateRangeString}
                            </Text>
                            <Chart
                                data={Object.values(resourceProportions)}
                                labels={labels}
                                xLabel={dayScale ? 'Day' : 'Hour'}
                                title={'Resources'}
                                datasetLabels={allUniqueResources}
                                yLabel={'% of Queries'}
                                type={'stackedbar'}
                                suggestedYMax={1}
                                fill
                            />
                        </CardBody>
                    </Card> */}

                    {/* <Card variant='outline'>
                        <CardBody p={['2', null, '5']}>
                            <Heading size='md' textAlign={'center'}>Tutor Engine Versions</Heading>
                            <Text size='md' textAlign={'center'} color={lightTextColor}>
                                {formatDateLong(daysInRange[0])}
                                {dateRangeString}
                            </Text>
                            <Chart
                                data={[tutorEngineVersionsFeedback]}
                                labels={allUniqueTutorEngineVersions}
                                xLabel={'Version'}
                                title={'Feedback'}
                                datasetLabels={['Feedback']}
                                yLabel={'% Satisfaction'}
                                type={'bar'}
                                suggestedYMax={100}
                                fill
                            />
                        </CardBody>
                    </Card> */}

                    {/* <Card variant='outline'>
                        <CardBody p={['2', null, '5']}>
                            <Heading size='md' textAlign={'center'}>Terminations</Heading>
                            <Text size='md' textAlign={'center'} color={lightTextColor}>
                                {formatDateLong(daysInRange[0])}
                                {dateRangeString}
                            </Text>
                            <Chart
                                data={[Object.values(allDaysTerminations)]}
                                labels={labels}
                                xLabel={dayScale ? 'Day' : 'Hour'}
                                title={'Terminations'}
                                datasetLabels={['Terminations']}
                                yLabel={'% Terminations'}
                                type={'line'}
                                suggestedYMax={100}
                                fill
                            />
                        </CardBody>
                    </Card> */}
                </> : null}
        </>
    )
}

function avg(numbers, weights = undefined) {
    let average
    if (weights) {
        const sumOfWeights = weights.reduce((acc, number) => acc + number, 0)
        average = numbers.reduce((acc, number, i) => acc + number * (weights[i] / sumOfWeights), 0)
    } else {
        average = numbers.reduce((acc, number) => acc + number, 0) / numbers.length
    }
    return average
}

function Numbers({ averageConversationLength, conversationsCreated, averageExchangeCost, averageLatency, daysInRange }) {
    const lightTextColor = useColorModeValue('slate.400', 'slate.500')

    return (
        <Card as={GridItem} colSpan={[1, null, null, null, 2]} variant={'outline'}>
            <CardBody>
                <HStack gap='3' flexWrap={'wrap'}>
                    <Stat>
                        <StatLabel fontSize={['sm', null, 'md']}>Total Conversations Created</StatLabel>
                        <StatNumber>{conversationsCreated}</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel fontSize={['sm', null, 'md']}>Average Conversation Length</StatLabel>
                        <StatNumber>{averageConversationLength.toFixed(2)}</StatNumber>
                    </Stat>

                    {/* <Stat>
                        <StatLabel fontSize={'md'}>Daily Active Users</StatLabel>
                        <StatNumber>{daySummary.numActiveUsers}</StatNumber>
                    </Stat> */}

                    <Stat>
                        <StatLabel fontSize={['sm', null, 'md']}>Average Exchange Cost</StatLabel>
                        <StatNumber>${averageExchangeCost.toFixed(2)}</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel fontSize={['sm', null, 'md']}>Average Latency</StatLabel>
                        <StatNumber>{averageLatency.toFixed(2)}s</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel fontSize={['sm', null, 'md']}>Total Cost</StatLabel>
                        <StatNumber>${(conversationsCreated * averageConversationLength * averageExchangeCost).toFixed(2)}</StatNumber>
                    </Stat>
                </HStack>
                <Text size='md' color={lightTextColor}>
                    {formatDateLong(daysInRange[0])}
                    {daysInRange[0] != daysInRange[daysInRange.length - 1] ? ' to ' + formatDateLong(daysInRange[daysInRange.length - 1]) : ''}
                </Text>
            </CardBody>
        </Card>
    )
}
