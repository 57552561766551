import {
    Button,
    Flex,
    FormLabel,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Switch,
    Tooltip,
    Text,
    useToast,
    Icon,
    Tag,
    useColorModeValue,
    Grid,
    Hide,
    useBreakpointValue,
    useBreakpoint,
    Card,
    CardBody,
    OrderedList,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react"
import {
    BASE_DOMAIN,
    CLOUD_FUNCTION_URL,
    MAX_CONVERSATION_SIZE,
    MOBILE_BREAKPOINTS,
    REQUEST_FEEDBACK_EVERY,
} from "../../lib/CONSTANTS";
import { useEffect, useRef, useState } from "react"
import Chat from "./Chat";
import { analytics, auth } from "../../lib/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import useUserDoc from "../../hooks/useUserDoc";
import { Exchange, Query } from "../../types/types";
import { getTimestamp } from "../../lib/date_utils";
import sha1 from "sha1";
import { useLocation, useNavigate, useParams, Link as ReactRouterLink } from "react-router-dom";
import { ArrowBackIcon, ChevronDownIcon, LinkIcon, StarIcon } from "@chakra-ui/icons";
import { logEvent } from "firebase/analytics";
import { useFirebaseRoutesAnalytics } from "../../hooks/useFirebaseRoutesAnalytics";
import useSingleCourse from "../../hooks/useSingleCourse";
import { loadBundle, Timestamp } from "firebase/firestore";
import useList from "../../hooks/useList";
import useURLParams from "../../hooks/useURLParams";
import useSingleConversation from "../../hooks/useSingleConversation";
import SourcesPane from "./SourcesPane"
import useStickyState from "../../hooks/useStickyState";
import { cutOffText } from "../../lib/text_utils";
import { BiSlideshow } from "react-icons/bi";
import Quota, { computeActualQueriesInStock } from "./Quota";
import useUserCourseDoc from "../../hooks/useUserCourseDoc";
import { addFeedbackToConversationDoc } from "../../lib/database";
import FeedbackBody from "./FeedbackBody";
import { MdMailOutline } from "react-icons/md";

type RenderMode = 'local' | 'db'

export default function Tutor() {
    useFirebaseRoutesAnalytics();

    const [user, userLoading, userError] = useAuthState(auth)
    const [userDoc, isUserDoc] = useUserDoc(user)

    const toast = useToast();
    const breakpoint = useBreakpoint()

    const params = useParams()
    const [urlParams, urlParamsLoading] = useURLParams()
    const navigate = useNavigate()
    const location = useLocation()

    const responseListRef = useRef(null)

    const [areSourcesToShow, setAreSourcesToShow] = useState(false)
    const [displaySources, setDisplaySources] = useState(true)
    const [feedbackInChat, setFeedbackInChat] = useState(false)

    const [course, isCourseLoading] = useSingleCourse(params.courseId)
    const [userCourseDoc, isUserCourseDoc] = useUserCourseDoc(user.uid, params.courseId, course?.controls.quota.maxQueriesAccumulated)

    console.log("re-rendered")

    const resourceClass = decodeURIComponent(params.resourceClass)
    const resourceName = decodeURIComponent(params.resourceName)

    const adminLevel = isUserDoc ? userDoc.adminLevels[params.courseId] : 0

    const [renderMode, setRenderMode] = useState<RenderMode>("db")

    const [
        localResponses,
        addLocalResponse,
        updateLocalResponseAt,
        clearLocalResponses,
        setLocalResponses,
        removeResponseAt
    ] = useList<Exchange>([]);
    const [conversationID, setConversationID] = useState(undefined)
    const [waitingOnResponse, setWaitingOnResponse] = useState(false)
    const [focusSourceId, setFocusSourceId] = useState(undefined)

    const [conversation, isConversationInvalid] = useSingleConversation(params.courseId, conversationID)

    const [responseLoadingMessage, setResponseLoadingMessage] = useState(undefined);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)

    // const [adminDisplay, setAdminDisplay] = useStickyState(isAdmin, "adminDisplay");

    // scroll to new responses
    useEffect(() => {
        if (responseListRef.current) {
            const lastChild = responseListRef.current.lastElementChild
            lastChild?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
    }, [localResponses.length, responseListRef])

    // add conversationID to url if not there
    useEffect(() => {
        if (!conversationID) return

        navigate({
            pathname: location.pathname,
            search: `?id=${conversationID}`
        })
    }, [conversationID])

    useEffect(() => {
        if (!isCourseLoading && isUserDoc) {
            if (!Object.keys(userDoc.adminLevels).includes(params.courseId) || userDoc.adminLevels[params.courseId] < 0 || course.controls.paused) {
                navigate("/app/dashboard")
            }
        }
    }, [isUserDoc, userDoc, course, isCourseLoading])

    // setConversationID if it's in url
    useEffect(() => {
        if (urlParamsLoading) return

        if (urlParams.has('id')) setConversationID(urlParams.get('id'))
    }, [urlParams, urlParamsLoading])

    // update localResponses on conversation doc update
    useEffect(() => {
        if (!conversation) return

        if (conversation.userDocRef.id != user.uid) navigate('/app')

        if (conversation.responses.length < localResponses.length) return

        setLocalResponses(conversation.responses)
        setRenderMode('db')
    }, [conversation])

    const onShareableLinkClick = async (exchangeIndex = undefined) => {
        if (!conversationID) return

        // const urlParam = exchangeIndex != undefined ? `?exchange=${exchangeIndex}` : ""

        const shareableLink = `${BASE_DOMAIN}/app/${params.courseId}/share/${conversationID}`;

        logEvent(analytics, "shareable_link_generated")

        try {
            await navigator.clipboard.writeText(shareableLink);
            toast({
                title: "Shareable link copied to clipboard!",
                position: "top",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            console.log("Shareable link copied to clipboard");
        } catch (err) {
            console.error("Failed to copy shareable link to clipboard: ", err);
            toast({
                title: "Failed to copy shareable link to clipboard.",
                description: `Error: ${err}`,
                position: "top",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const sendQuery = async (queryText) => {
        const actualQueries = computeActualQueriesInStock(userCourseDoc.queriesLeft, userCourseDoc.timestampLastRegenerated, course.controls.quota.maxQueriesAccumulated, course.controls.quota.minutesUntilIncrement)
        if (actualQueries <= 0) {
            toast({
                title: "Out of queries!",
                description: "You've used up all of your queries for now. Another will regenerate in 10 minutes.",
                position: "top",
                status: "info",
                duration: null,
                isClosable: true,
            })
            return
        }

        if (localResponses.length >= MAX_CONVERSATION_SIZE) {
            toast({
                title: "Max Conversation Size Reached!",
                description: "For performance reasons, please open a new conversation with ATA to continue chatting.",
                position: "top",
                status: "info",
                duration: null,
                isClosable: true,
            })
            return
        }

        setWaitingOnResponse(true)
        setRenderMode('local')

        let convoID = conversationID;
        if (convoID == undefined) {
            convoID = createConversationID(userDoc.uid);
            setConversationID(convoID);

            // analytics
            logEvent(analytics, "start_new_conversation");
        }

        const data: Query = {
            "queryText": queryText,
            "uid": userDoc.uid,
            "userName": userDoc.displayName,
            "conversationDocId": convoID,
            "resourceName": resourceName,
            "resourceClass": resourceClass,
            "courseId": course.id,
        }

        let exchange: Exchange = {
            queryText: queryText,
            responseText: "",
            sources: undefined,
            stats: undefined,
            log: undefined,
            timestamp: Timestamp.now(),
            tutorEngineVersion: undefined
        }

        if ((localResponses.length + 1) % REQUEST_FEEDBACK_EVERY == 0) {
            console.log("setFeedbackInChat: true")
            setFeedbackInChat(true)
        }

        // add user query to responses
        addLocalResponse(exchange)


        try {
            const accessToken = await auth.currentUser.getIdToken()

            const headers = {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }


            setResponseLoadingMessage("Retrieving relevant documents...")
            const response = await fetch(CLOUD_FUNCTION_URL, {
                method: "POST",
                headers,
                body: JSON.stringify(data),
            })

            if (!response.ok) {
                const errorData = (await response.json()).error
                toast({
                    title: "Oops! Something went wrong",
                    description: errorData,
                    position: "top",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
                setResponseLoadingMessage(undefined)
                setWaitingOnResponse(false)
                setRenderMode('db')

                //@ts-ignore
                setLocalResponses(responses => responses.slice(0, -1))
                return
            }

            const reader = response.body.getReader()
            const decoder = new TextDecoder()

            let incomingString = ""
            while (true) {
                let { done, value } = await reader.read()
                let chunks

                incomingString += decoder.decode(value)
                try {
                    const toParse = "[" +
                        incomingString.substring(0, incomingString.length - 1) // remove last character because we add a comma after each JSON chunk
                        + "]"
                    chunks = JSON.parse(toParse)
                    incomingString = ""
                } catch {
                    // value isn't a full JSON string yet
                    chunks = []
                }
                // process each chunk
                for (const chunk of chunks) {
                    // chainOfThought finished (intermediate)
                    if (Object.keys(chunk).includes("chain_of_thought")) {
                        exchange["chainOfThought"] = chunk["chain_of_thought"]
                        setResponseLoadingMessage(undefined);
                    }

                    // is a streaming chunk
                    if (Object.keys(chunk).includes("response_text")) {
                        exchange["responseText"] += chunk["response_text"]
                    }

                    // last chunk
                    if (Object.keys(chunk).includes("usage")) {
                        exchange["usage"] = chunk["usage"]
                    }

                    if (Object.keys(chunk).includes("error_msg")) {
                        toast({
                            title: "Oops! Something went wrong",
                            description: `Error: ${chunk['error_msg']}`,
                            position: "top",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                        setResponseLoadingMessage(undefined)
                        setWaitingOnResponse(false)
                        setRenderMode('db')
                        //@ts-ignore
                        setLocalResponses(responses => responses.slice(0, -1))
                        return
                    }

                    updateLocalResponseAt(localResponses.length, exchange)
                }

                if (done) {
                    setWaitingOnResponse(false)
                    return
                }
            }
        } catch (error) {
            logEvent(analytics, "query_failed")

            console.log('here', error)
            toast({
                title: "Oops! Something went wrong",
                description: `Error: ${error}`,
                position: "top",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
            setResponseLoadingMessage(undefined)
            setWaitingOnResponse(false)
            setRenderMode('db')
            //@ts-ignore
            setLocalResponses(responses => responses.slice(0, -1))
        }
    }

    const handleReferenceTagClick = (id: string) => {
        setDisplaySources(true)
        setFocusSourceId(id)
    }

    const handleFeedbackInChat = (feedback) => {
        setFeedbackInChat(false)
        addFeedbackToConversationDoc(params.courseId, conversationID, feedback)
    }

    const handleSaveFeedbackFromModal = (feedback) => {
        setFeedbackModalOpen(false)
        addFeedbackToConversationDoc(params.courseId, conversationID, feedback)
    }

    const inputDisabled = conversation?.isTerminated

    const sourcesRendered = displaySources && areSourcesToShow && !feedbackInChat
    const renderChat = ((MOBILE_BREAKPOINTS.includes(breakpoint) && !sourcesRendered) || !MOBILE_BREAKPOINTS.includes(breakpoint))

    return (
        <Flex flexDir={"column"} h="full" px={'3'} bg={useColorModeValue('slate.50', 'slate.800')} alignItems={'center'} overflowY={"hidden"} position={'relative'}>

            <TitleAndControls
                conversationTitle={conversation?.title || 'Untitled'}
                isAdmin={false}
                onCopyShareableLink={onShareableLinkClick}
                renderShareableLink={conversationID !== undefined}
                isAdminDisplayOn={false}
                setAdminDisplay={() => { }}
                resourceName={resourceName}
                resourceClass={resourceClass}
                areSourcesVisible={displaySources}
                openFeedbackModal={() => setFeedbackModalOpen(true)}
                renderSourcesToggle={areSourcesToShow}
                renderAnnotationLink={adminLevel >= 3}
                annotationLink={conversationID ? `/app/${params.courseId}/admin/annotations/${conversationID}` : ''}
                toggleSources={() => setDisplaySources(!displaySources)}
                maxQueryStockSize={isCourseLoading ? undefined : course.controls.quota.maxQueriesAccumulated}
                queriesInStock={isUserCourseDoc ? userCourseDoc.queriesLeft : undefined}
                timestampLastRegenerated={isUserCourseDoc ? userCourseDoc.timestampLastRegenerated : undefined}
                minutesPerQueryRegen={isCourseLoading ? undefined : course.controls.quota.minutesUntilIncrement}
            />

            <Grid gridTemplateColumns={sourcesRendered ? ['1fr', null, null, '1fr 1fr'] : '1fr'} gap='3' h='full' overflowY={'hidden'} w='full'>
                {renderChat && <Chat
                    promptDisabled={inputDisabled}
                    responses={localResponses}
                    resourceName={resourceName}
                    resourceClass={resourceClass}
                    onCopyResponseLink={onShareableLinkClick}
                    onQuerySubmit={sendQuery}
                    loadingMessage={responseLoadingMessage}
                    onFeedback={handleFeedbackInChat}
                    isAdmin={false}
                    pastingDisabled={course?.controls.pastingDisabled}
                    responseListRef={responseListRef}
                    onReferenceTagClick={handleReferenceTagClick}
                    tutorState={conversation?.tutorState}
                    showFeedbackOnLast={feedbackInChat}
                    disableSendingQuery={waitingOnResponse || feedbackInChat}
                    lastFeedback={conversation?.hasFeedback ? conversation.feedback[conversation.feedback.length - 1] : undefined}
                    lastTutorEngineVersion={conversation?.lastTutorEngineVersion}
                    conversationData={userCourseDoc ? userCourseDoc.conversations : []}
                />}

                <SourcesPane
                    responses={localResponses}
                    setAreSourcesToShow={setAreSourcesToShow}
                    courseId={params.courseId}
                    onClose={() => setDisplaySources(false)}
                    title={conversation?.resourceName ? cutOffText(conversation?.resourceName, 50, true) : ""}
                    display={sourcesRendered}
                    focusSourceId={focusSourceId}
                />
            </Grid>

            <Hide below="lg">
                <Button
                    onClick={() => navigate(`/app/${params.courseId}/`)}
                    position={'absolute'}
                    left='1.5'
                    top='1.5'
                    size={'sm'}
                    fontWeight={'400'}
                    variant={'ghost'}
                    _hover={{
                        background: useColorModeValue('slate.200', 'slate.700')
                    }}
                    colorScheme={'slate'}
                    leftIcon={<ArrowBackIcon />}
                >
                    Back to course
                </Button>
            </Hide>

            <FeedbackModal
                isOpen={feedbackModalOpen}
                onClose={() => setFeedbackModalOpen(false)}
                sendFeedback={handleSaveFeedbackFromModal}
                lastFeedback={conversation?.hasFeedback ? conversation.feedback[conversation.feedback.length - 1] : undefined}
            />

        </Flex>
    )
}

export function FeedbackModal({ isOpen, onClose, sendFeedback, lastFeedback = undefined }) {
    const feedbackValues: any = {}
    if (lastFeedback !== undefined) {
        feedbackValues.initialScaleValue = lastFeedback.helpfulness
        feedbackValues.initialTextValue = lastFeedback.comments
        feedbackValues.initialIssues = lastFeedback.issues
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent top='20%' maxW='3xl' w='full'>
                <ModalHeader>Update Feedback</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FeedbackBody sendFeedback={sendFeedback} {...feedbackValues} />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export function ConvoAdminCard({ learningObjective, plannedSubtasks, subtaskPointer, studentKnowledge, flow, lastTutorEngineVersion, ...otherArgs }) {
    return (
        <Card {...otherArgs}>
            <CardBody>
                <Text mb='3' fontStyle={'italic'} color='slate.500'>Admin Card</Text>
                {lastTutorEngineVersion && <Text mb='1'>Tutor Engine v{lastTutorEngineVersion}</Text>}
                {learningObjective ? <Text fontSize='lg' mb='3'><strong>{learningObjective.title}</strong>: {learningObjective.description}</Text> : <Text>no learning objective</Text>}
                {plannedSubtasks.length > 0 ? <OrderedList spacing='2'>
                    {plannedSubtasks.map((subtask, i) => <ListItem key={i}>{subtask.title}: {subtask.description} {subtaskPointer == i && <StarIcon />}</ListItem>)}
                </OrderedList> : <Text>no plan yet</Text>}
            </CardBody>
        </Card>
    )
}

function TopRightButtons({
    onShareableLinkClick,
    renderShareableLinkButton,
    renderQuota,
    quotaLeft,
    isAdmin,
    setAdminDisplay,
    adminDisplay,
}) {
    return (
        <Flex position={'absolute'} right='0' top='0' gap="3" flexDir={"column"} alignItems={"center"} pr="3" pt="3">
            {/* {renderQuota && <Quota quotaLeft={quotaLeft} />} */}
            {renderShareableLinkButton && (
                <Tooltip placement="left" hasArrow label="Copy shareable link">
                    <IconButton
                        aria-label="Shareable Link Button"
                        variant={"ghost"}
                        onClick={onShareableLinkClick}
                        icon={<LinkIcon />}
                    />
                </Tooltip>
            )}

            {isAdmin && (
                <Flex alignItems={"center"} flexDir={'column'}>
                    <Switch
                        id="adminDisplay"
                        isChecked={adminDisplay}
                        onChange={(e) => setAdminDisplay(e.target.checked)}
                    />
                    <FormLabel mb="0" mr="1" fontSize={"sm"} htmlFor="adminDisplay">
                        admin display
                    </FormLabel>
                </Flex>
            )}
        </Flex>
    )
}

export function TitleAndControls({ conversationTitle, isAdmin, renderShareableLink, isAdminDisplayOn, setAdminDisplay, resourceName, resourceClass, annotationLink, renderAnnotationLink, onCopyShareableLink, renderSourcesToggle, toggleSources, areSourcesVisible, openFeedbackModal = undefined, maxQueryStockSize = undefined, queriesInStock = undefined, timestampLastRegenerated = undefined, minutesPerQueryRegen = undefined, isShareableLink = false }) {

    const title = useBreakpointValue({
        'base': conversationTitle ? cutOffText(conversationTitle, 15, true) : 'loading',
        'xl': conversationTitle ? cutOffText(conversationTitle, 50, true) : 'loading'
    })

    const resourceNameDisplay = useBreakpointValue({
        'base': resourceName ? cutOffText(resourceName, 7, true) : 'loading',
        'xl': resourceName ? cutOffText(resourceName, 50, true) : 'loading'
    })

    const hasItems = (isAdmin || renderShareableLink)

    const renderQuota = (maxQueryStockSize !== undefined && queriesInStock !== undefined && timestampLastRegenerated !== undefined && minutesPerQueryRegen !== undefined)

    return (
        <Flex my='1.5' alignItems={'center'} justifyContent={'center'} gap='3' w='full' flexWrap={'wrap'}>

            <Flex alignItems={'center'} gap='1'>
                <Tooltip label={resourceName}>
                    <Tag colorScheme='primary'>{resourceNameDisplay}</Tag>
                </Tooltip>
            </Flex>
            {hasItems ? <Menu >
                <MenuButton
                    as={Button}
                    variant={'ghost'}
                    size='sm'
                    color='slate.500'
                    fontWeight={'400'}
                    rightIcon={<ChevronDownIcon />}
                >
                    {title}
                </MenuButton>
                <MenuList>
                    {renderShareableLink && <MenuItem icon={<LinkIcon />} onClick={onCopyShareableLink}>
                        Copy Shareable Link
                    </MenuItem>}
                    {isAdmin && isAdminDisplayOn && <MenuItem onClick={() => setAdminDisplay(false)}>Turn off Admin Display</MenuItem>}
                    {isAdmin && !isAdminDisplayOn && <MenuItem onClick={() => setAdminDisplay(true)}>Turn on Admin Display</MenuItem>}
                    {renderSourcesToggle && <MenuItem icon={<Icon as={BiSlideshow} />} onClick={toggleSources}>{areSourcesVisible ? 'Hide Slides' : 'Show Slides'}</MenuItem>}
                    {openFeedbackModal !== undefined && <MenuItem icon={<Icon as={MdMailOutline} />} onClick={openFeedbackModal}>Feedback</MenuItem>}
                    {renderAnnotationLink && <MenuItem as={ReactRouterLink} to={annotationLink}>Annotate</MenuItem>}
                </MenuList>
            </Menu> : <Text fontSize='sm' color='slate.500' px='2'>{title}</Text>}
            {isShareableLink && <Tag colorScheme={'primary'} variant='solid'>Shared</Tag>}

            {renderQuota && <Quota
                maxStockSize={maxQueryStockSize}
                queriesInStock={queriesInStock}
                timestampLastRegenerated={timestampLastRegenerated}
                minutesPerRegen={minutesPerQueryRegen}
            />}

        </Flex>
    )
}

function createConversationID(uid) {
    const intermediate = `${uid}-${getTimestamp()}`
    // Calling createHash method
    const hash = sha1(intermediate)

    return hash
}
