import { useState, useCallback } from 'react';

export default function useList<T>(initialList = []) {
    const [list, setList] = useState<T[]>(initialList)

    const addItem = useCallback((item) => {
        setList((prevList) => [...prevList, item])
    }, []);

    const updateItemAt = useCallback((index, newItem) => {
        setList((prevList) =>
            prevList.map((item, i) => (i === index ? newItem : item))
        )
    }, [])

    const clearList = useCallback(() => {
        setList([])
    }, [])

    const removeItemAt = (i: number) => {
        console.log("here", i, list.length)
        setList((prevList) => prevList.filter((v, j) => i != j))
    }

    return [
        list,
        addItem,
        updateItemAt,
        clearList,
        setList,
        removeItemAt
    ] as [
        T[],
        (item: T) => void,
        (i: number, item: T) => void,
        () => void,
        (items: T[]) => void,
        (i: number) => void
    ]
}
