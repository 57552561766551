import { ForwardedRef, MutableRefObject } from "react";
import Response from "./Response";
import { Exchange, Feedback } from "../../types/types";
import { Box } from "@chakra-ui/react";


export default function Pair({
    queryText,
    responseText,
    onFeedback,
    isAdmin,
    sources,
    log,
    onCopyResponseLink,
    stats,
    onReferenceTagClick,
    lastFeedback = undefined,
    showFeedback = false,
    userName = undefined,
    isShare = false,
    loadingMessage = ""
}:
    Exchange & {
        isAdmin: boolean,
        tutorResponseRef?: (el: Element) => void,
        onCopyResponseLink: (i: number) => void,
        onFeedback: () => void,
        showFeedback?: boolean,
        loadingMessage: string,
        onReferenceTagClick: (id: string) => void,
        isShare: boolean,
        lastFeedback?: Feedback,
        userName?: string
    }) {

    return (
        <>
            <Response
                loadingMessage={""}
                content={queryText}
                speaker={"student"}
                showFeedback={false}
                stats={null}
                userName={userName}
                sources={null}
                sendFeedback={undefined}
                log={null}
                onReferenceTagClick={undefined}
                isShare={isShare}
                lastFeedback={undefined}
            />
            {/* <Box w='full'> */}
            <Response
                content={responseText}
                speaker="tutor"
                loadingMessage={loadingMessage}
                sources={sources}
                stats={stats}
                userName={userName}
                showFeedback={showFeedback}
                sendFeedback={onFeedback}
                log={log}
                onReferenceTagClick={onReferenceTagClick}
                isShare={isShare}
                lastFeedback={lastFeedback}
            />
            {/* </Box> */}
        </>
    )
}