import { Heading, Box, Center, Spinner, Grid, Flex, Button } from "@chakra-ui/react";
import useSingleCourse from "../../../hooks/useSingleCourse";
import { Link, useParams } from "react-router-dom";
import { getResourceList } from "../../resources";
import { ExtraCourseData, ResourceMetadata } from "../../../types/types";
import ResourceEntry from "./ResourceEntry";
// load resources

// show every resource as card

// button for ingesting new resource

export default function ResourceManager() {
    const params = useParams()
    const [course, isCourseLoading, extraCourseData] = useSingleCourse(params.courseId, true)

    if (isCourseLoading) return <Center py='32'><Spinner /></Center>

    const resources = getResourceList(course.resources, extraCourseData as ExtraCourseData)

    return (
        <Box>
            <Flex alignItems='center' justifyContent='space-between'>
                <Heading mb='12'>Resource Manager</Heading>
                <Button as={Link} to={`/app/${params.courseId}/admin/resources/update`} color='white' colorScheme={'primary'} fontWeight='400'>Add Resource</Button>
            </Flex>

            <Grid gridTemplateColumns='1fr 1fr 1fr' gap='6'>
                {resources.map((r, i) =>
                    <ResourceEntry
                        courseId={params.courseId}
                        resourceTypes={course.schema[r.resourceClass]}
                        {...r}
                        key={i}
                    />
                )}
            </Grid>
        </Box>
    )

}