import React, { useEffect, useRef, useState } from 'react'

import { VStack, Image, Divider, useColorModeValue, Text, Box, Tag, Flex, Heading, CloseButton, Tooltip, HStack, IconButton } from '@chakra-ui/react'

import { getURLForPage } from '../../lib/storage'
import { Exchange, Source } from "../../types/types";
import useList from "../../hooks/useList";
import { capitalizeWords } from '../../lib/text_utils';
import useMap from '../../hooks/useMap';
import { cloneDeep } from 'lodash';
import { AddIcon } from '@chakra-ui/icons';

const supportedTypes = ['slides', 'handout', 'transcript']

// const REFERENCE_ID_REGEX = /(\[page: '.+?'\])/g
export const REFERENCE_ID_REGEX = /(\<ReferenceID>.+?<\/ReferenceID>)/g

const parseReferenceTag = (resourceTag: string) => {
    const tokens = resourceTag.split("-")
    return {
        resourceClass: tokens[0],
        resourceName: tokens[1],
        resourceType: tokens[2],
        file: tokens[3],
        page: parseInt(tokens[tokens.length - 1])
    }
}

type Reference = {
    id: string
    url: string
    page: number
}



export default function SourcesPane({ title, responses, display, setAreSourcesToShow, courseId, onClose, focusSourceId, styleProps = {} }: { title: string, display: boolean, onClose: () => void, focusSourceId: string, responses: Exchange[], setAreSourcesToShow: (v: boolean) => void, courseId: string, styleProps?: object }) {

    const [responsesProcessed, setResponsesProcessed] = useState(0)
    const sourcesTrayRef = useRef(null)

    const [classNameTypeFileToReferences, clearReferences, updateReferencesOfClassNameTypeFile, setReferencesMap] = useMap<Reference[]>({})

    const paneBorderColor = useColorModeValue('slate.200', 'slate.700')
    const paneBg = useColorModeValue('white', 'slate.800')
    const sourcesTrayBg = useColorModeValue('slate.100', 'slate.700')

    const combResponseTextForSources = async (responseText) => {
        const ids = []
        const parts = responseText.split(REFERENCE_ID_REGEX)
        for (let i = 0; i < parts.length; i++) {
            if (i % 2 == 1) {
                const id = parts[i].split(">")[1].split("<")[0]
                ids.push(id)
            }
        }

        const promises = ids.map((id, i) => getURLForPage(`${courseId}/${id}.png`))
        const urls = await Promise.all(promises)

        setReferencesMap((prevReferencesMap) => {
            const tmp = cloneDeep(prevReferencesMap)
            for (let i = 0; i < urls.length; i++) {
                const { resourceClass, resourceName, resourceType, file, page } = parseReferenceTag(ids[i])
                const key = `${resourceClass}:${resourceName}:${resourceType}:${file}`
                if (key in tmp) {
                    if (!tmp[key].some(({ id }) => id == ids[i])) {
                        let insertIndex = tmp[key].findIndex(({ page: page2 }) => page2 > page)
                        if (insertIndex == -1) {
                            insertIndex = tmp[key].length
                        }
                        tmp[key].splice(insertIndex, 0, { id: ids[i], url: urls[i], page })
                    }
                } else {
                    tmp[key] = [{ id: ids[i], url: urls[i], page }]
                }
            }

            return tmp
        })
    }

    // check responseTexts for retrieved slides
    useEffect(() => {
        if (!responses || !responses[responses.length - 1]?.responseText) return

        let lastFullResponse = 0
        for (let i = responsesProcessed; i < responses.length; i++) {
            combResponseTextForSources(responses[i].responseText)
            if (Object.keys(responses[i]).includes('log')) lastFullResponse = i + 1
        }
        setResponsesProcessed(lastFullResponse)

    }, [responses])

    useEffect(() => {
        if (Object.keys(classNameTypeFileToReferences).length > 0) {
            setAreSourcesToShow(true)
        }
    }, [classNameTypeFileToReferences])

    // scroll to source when its reference tag is clicked
    useEffect(() => {
        if (!focusSourceId || !sourcesTrayRef.current || Object.keys(classNameTypeFileToReferences).length == 0 || !display) return

        let resourceIndex = -1
        let sourceIndex = -1

        const keys = Object.keys(classNameTypeFileToReferences)
        keys.sort()

        for (let i = 0; i < keys.length; i++) {
            sourceIndex = classNameTypeFileToReferences[keys[i]].findIndex(({ id }, i) => id == focusSourceId)
            if (sourceIndex !== -1) {
                resourceIndex = i
                break
            }
        }

        if (resourceIndex >= 0) {
            const focusResource = sourcesTrayRef.current.children[resourceIndex]
            const focusSourceElement = focusResource.children[sourceIndex + 1] as Element
            if (focusSourceElement !== undefined) {
                focusSourceElement.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            console.error("Couldn't find the source clicked:", focusSourceId)
        }
    }, [focusSourceId, display])

    const dividerBorderColor = useColorModeValue('slate.200', 'slate.500')
    const sourceGroupTitleColor = useColorModeValue('slate.600', 'slate.300')

    const showExtraPage = async (key: string, referenceId: string, pageToAdd: number) => {
        const tokens = referenceId.split("-")
        tokens[tokens.length - 1] = pageToAdd.toString()
        const id = tokens.join("-")
        const path = `${courseId}/${id}.png`
        const url = await getURLForPage(path)
        const tmp = [...classNameTypeFileToReferences[key]]
        let insertIndex = tmp.findIndex(({ page: page2 }) => page2 > pageToAdd)
        if (insertIndex == -1) {
            insertIndex = tmp.length
        }
        tmp.splice(insertIndex, 0, { id, url, page: pageToAdd })
        updateReferencesOfClassNameTypeFile(key, tmp)
    }

    if (Object.keys(classNameTypeFileToReferences).length == 0 || !display) return

    const keys = Object.keys(classNameTypeFileToReferences)
    keys.sort()

    return (
        <Flex
            borderRadius={'6'}
            bg={paneBg}
            borderWidth={'1px'}
            borderColor={paneBorderColor}
            // boxShadow={'md'}
            h='calc(100% - 1.5rem)'
            flexDir={'column'}
            mb={[0, null, null, '3']}
            overflow={'hidden'}
        >
            <Flex px='3' py='1.5' mb='3' borderBottomWidth={'1px'} borderColor={paneBorderColor} alignItems={'center'} justifyContent={'space-between'}>
                <Heading fontSize={'lg'} fontWeight={'400'}>Slides</Heading>
                <Tooltip label="Hide Sources">
                    <CloseButton onClick={onClose} />
                </Tooltip>
            </Flex>
            <VStack
                gap='12'
                mx='3'
                h='100%'
                borderRadius={'6'}
                mt='1.5'
                mb='3'
                overflowY={"auto"}
                {...styleProps}
                p={['2', null, null, '6']}
                bg={sourcesTrayBg}
                ref={sourcesTrayRef}
                alignItems={'stretch'}
            >
                {
                    keys.map((k, i) => {
                        const references = classNameTypeFileToReferences[k]
                        const tokens = k.split(":")
                        const title = tokens.slice(1).join(" ")
                        return <VStack key={i} gap='8'>
                            <HStack w='full' alignItems={'center'}>
                                <Divider borderColor='slate.300' flex='1' />
                                <Text px='3' color={sourceGroupTitleColor} fontWeight={'700'} textAlign={'center'} whiteSpace={'nowrap'}>{title}</Text>
                                <Divider borderColor='slate.300' flex='1' />
                            </HStack>
                            {references.map(
                                ({ id, url, page }, j) =>
                                    <SourceDisplay
                                        src={url}
                                        id={id}
                                        hasBefore={(j == 0 && page > 1) || (j > 0 && references[j - 1].page < page - 1)}
                                        addBefore={() => showExtraPage(k, id, page - 1)}
                                        hasAfter={(j == references.length - 1) || (j < references.length - 1) && references[j + 1].page > page + 1}
                                        addAfter={() => showExtraPage(k, id, page + 1)}
                                        key={`${i}-${j}`}
                                    />
                            )
                            }
                        </VStack>
                    })
                }
            </VStack>
        </Flex>
    )
}


function SourceDisplay({ src, id, hasBefore = false, hasAfter = false, addBefore, addAfter }: {
    src: string,
    id: string,
    hasBefore?: boolean,
    hasAfter?: boolean,
    addBefore?: () => void,
    addAfter?: () => void
}) {
    if (!src) return

    let { resourceClass, resourceName, resourceType, page } = parseReferenceTag(id)

    return (
        <Box position={'relative'} role="group">
            <Tag
                position={'absolute'}
                variant={'subtle'}
                left='0'
                top='0'
                borderRadius={0}
                bg='primary.500'
                color='white'
            >
                Page {page}
            </Tag>

            {hasBefore && <Tooltip label="See previous slide" ><IconButton
                onClick={addBefore}
                aria-label='show page before'
                display={'none'}
                position={'absolute'}
                colorScheme='primary'
                left='50%'
                size={'xs'}
                borderRadius={'full'}
                color={'white'}
                top='0'
                transform={'translate(-50%,-50%)'}
                _groupHover={{
                    display: 'block'
                }}
            >
                <AddIcon />
            </IconButton></Tooltip>}

            <Image
                src={src}
                width='100%'
                height='auto'
                borderWidth={2}
                borderColor={'slate.200'}
            />

            {hasAfter && <Tooltip label="See next slide"><IconButton
                onClick={addAfter}
                aria-label='show page after'
                display={'none'}
                position={'absolute'}
                size='xs'
                colorScheme='primary'
                left='50%'
                borderRadius={'full'}
                color='white'
                bottom='0'
                transform={'translate(-50%,50%)'}
                _groupHover={{
                    display: 'block'
                }}
            >
                <AddIcon />
            </IconButton></Tooltip>}
        </Box>
    )
}

export function parseInfoFromSourceId(id: string) {
    const tokens = id.split("-")
    const resourceClass = tokens[0]
    const resourceName = tokens[1]
    const sourceType = tokens[tokens.length - 2]

    const number = id.split(`${sourceType}-`)[1]
    return [resourceClass, resourceName, sourceType, number]
}