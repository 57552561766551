import { Course, Semester } from "../../../types/types";

// NOTE: only months are zero-indexed
const SEMESTER_TO_START_AND_END_DATES: { [key in Semester]: { startMonth: number, startDay: number, endMonth: number, endDay: number } } = {
    Summer: {
        endDay: 30,
        endMonth: 7,
        startDay: 1,
        startMonth: 5
    },
    Fall: {
        endDay: 20,
        endMonth: 11,
        startDay: 1,
        startMonth: 8
    },
    Spring: {
        endDay: 20,
        endMonth: 5,
        startDay: 10,
        startMonth: 0
    }
}

export function getStartAndEndOfCourse(course: Course): [Date, Date] {
    const { semester, year } = course

    const { startMonth, endMonth, startDay, endDay } = SEMESTER_TO_START_AND_END_DATES[semester]
    const startDate = new Date(year, startMonth, startDay)
    const endDate = new Date(year, endMonth, endDay)

    return [startDate, endDate]
}