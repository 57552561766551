import { QuestionIcon } from "@chakra-ui/icons"
import { Card, HStack, Icon, Text, useColorModeValue } from "@chakra-ui/react"
import { doc } from "firebase/firestore"
import { BsQuestion } from "react-icons/bs"
import { db } from "../../lib/firebase"
import { useDocument } from 'react-firebase-hooks/firestore'
import { useParams } from "react-router-dom"



export default function SampleQuestions({ resourceClass, resourceName, onQuestionClick }) {
    const params = useParams()
    const [questionsDoc, questionsDocLoading, questionsDocError] = useDocument(doc(db, 'Courses', params.courseId, 'Data', 'questions'))

    const questions = []
    if (!questionsDocLoading && !questionsDocError && Object.keys(questionsDoc.data()).includes(resourceClass) && Object.keys(questionsDoc.data()[resourceClass]).includes(resourceName)) {
        questions.push(...questionsDoc.data()[resourceClass][resourceName])
    }

    return (
        <HStack
            w='full'
            overflowX={'scroll'}
            gap='2'
            sx={{
                "::-webkit-scrollbar": {
                    display: "none",
                },
            }}
            my='2'
        >
            {questions.map((q, i) => <SampleQuestion key={i} question={q} onClick={() => onQuestionClick(q)} />)}
        </HStack>
    )
}

function SampleQuestion({ question, onClick }: { question: string, onClick: () => void }) {
    return (
        <HStack onClick={onClick} bg={useColorModeValue('white', 'slate.700')} borderWidth={'1px'} p='1' borderColor={useColorModeValue('slate.100', 'slate.600')} wrap={'nowrap'} gap='1' alignItems={'center'} cursor='pointer' borderRadius={'sm'}>
            <Icon as={BsQuestion} fontSize={'md'} color={useColorModeValue('primary.600', 'primary.400')} />
            <Text whiteSpace={'nowrap'} flexGrow={1} fontSize='sm' flexShrink={0}>
                {question}
            </Text>
        </HStack>
    )
}